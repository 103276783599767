import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { CenterWrapper } from "./components/CenterWrapper";
import { Forms } from "./components/Forms";
import { Statistics } from "./components/Statistics";
import UserDetails from "./components/user-details/UserDetails"

const AppRoutes = [
    {
        index: true,
        element: <CenterWrapper />
    },
    {
        path: '/form-list',
        element: <Forms />
    },
    {
        path: '/statistics',
        element: <Statistics />
    },
    {
        path: '/counter',
        element: <Counter />
    },
    {
        path: '/user-details/:userId',
        element: <UserDetails />
    },
    {
        path: '/fetch-data',
        requireAuth: false,
        element: <FetchData />
    },
    {
        path: '*',
        index: true,
        element: <CenterWrapper />
    },
    ...ApiAuthorzationRoutes
];

export default AppRoutes;
