import React, { useState } from 'react';
import '../custom.css';
import { Document, Page } from 'react-pdf'
import { GrClose } from 'react-icons/gr';
import i18n from 'i18next';

const AdditionalInfoPopup = (props) => {
    return (
        <div className='popup'>
            <div className='popup-open-agreement d-flex flex-column'>
                <div className="d-flex flex-row justify-content-between" style={{ marginBottom: "30px" }}>
                    <div className="popup-header">
                    {i18n.t('additional info')}
                    </div>
                    <div className="popup-close-icon-wrapper d-flex">
                        <GrClose role="button" onClick={props.closePopup} />
                    </div>
                </div>

                <div className="popup-content d-flex flex-column">
                    {/* <Document file={`data:application/pdf;base64,${props.agreement}`}>
                        <Page pageNumber={1} />
                    </Document> */}
                    <object data={`data:application/pdf;base64,${props.info}`} >
                        <embed src={`data:application/pdf;base64,${props.info}`} />
                    </object>
                </div>
            </div>
        </div>
    );
}

export default AdditionalInfoPopup;