import React, { Component } from 'react';
import { AdminInfo } from './AdminInfo';
import { MemberInfo } from './MemberInfo';
import { Audio } from 'react-loader-spinner'
import '../custom.css';

export const UserInfoWrapper = (props) => {

    const renderLoader = () => {
        return (
            <div className="center-table-cell">
                <Audio
                    height="50"
                    width="50"
                    color='grey'
                    ariaLabel='loading'
                    wrapperClass='d-block'
                />
            </div>
        );
    }

    return (
        <div className="user-info" >
            {!props.isConfigLoaded ?
                renderLoader() :
                props.config.isAdmin ?
                    <AdminInfo {...props} /> : <MemberInfo {...props} />
            }
        </div>
    );
}
