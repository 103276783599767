import React, { Component } from 'react';
import '../custom.css';
import { Audio } from 'react-loader-spinner'
import { AiFillStar, AiOutlineSearch, AiOutlineCalendar } from 'react-icons/ai';
import { FaArrowsAltV, FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa';
import authService from './api-authorization/AuthorizeService'
import DatePicker, { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl";
import ReactTooltip from 'react-tooltip';
import i18n from 'i18next';
registerLocale("pl", pl);

export class ReservationList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reservations: {},
            loading: true,
            firstTabOpened: true,
            secondTabOpened: false,
            rezNumberSortOrder_firstTab: '',
            amountSortOrder_firstTab: '',
            potPointsSortOrder_firstTab: '',
            potDateSortOrder_firstTab: '',
            rezNumberSortOrder_secondTab: '',
            amountSortOrder_secondTab: '',
            potPointsSortOrder_secondTab: '',
            potDateSortOrder_secondTab: '',
            rezNumberSearch_firstTab: '',
            rezNumberSearch_secondTab: '',
            dateFrom_firstTab: '',
            dateFrom_secondTab: '',
            dateTo_firstTab: '',
            dateTo_secondTab: '',
        };
    }

    componentDidMount() {
        this.populateData(false);
    }

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    activateFirstTab = () => {
        this.setState({ ...this.state, firstTabOpened: true, secondTabOpened: false, firstTableView: this.state.reservations.earnedPoints, secondTableView: this.state.reservations.expiredEarnedPoints })
    }

    activateSecondTab = () => {
        this.setState({ ...this.state, firstTabOpened: false, secondTabOpened: true, firstTableView: this.state.reservations.potentialPoints, secondTableView: this.state.reservations.expiredPotentialPoints })
    }

    sortColumn = (column) => {
        let currentOrder = ''
        let newState = {};
        switch (column) {
            case 'rezNumber_firstTab':
                currentOrder = this.state.rezNumberSortOrder_firstTab;
                newState = {
                    ...this.state,
                    loading: true,
                    amountSortOrder_firstTab: '',
                    potPointsSortOrder_firstTab: '',
                    potDateSortOrder_firstTab: '',
                    rezNumberSortOrder_secondTab: '',
                    amountSortOrder_secondTab: '',
                    potPointsSortOrder_secondTab: '',
                    potDateSortOrder_secondTab: '',
                    rezNumberSortOrder_firstTab: currentOrder !== 'asc' ? 'asc' : 'dsc'
                }
                break;
            case 'amount_firstTab':
                currentOrder = this.state.amountSortOrder_firstTab;
                newState = {
                    ...this.state,
                    loading: true,
                    rezNumberSortOrder_firstTab: '',
                    potPointsSortOrder_firstTab: '',
                    potDateSortOrder_firstTab: '',
                    rezNumberSortOrder_secondTab: '',
                    amountSortOrder_secondTab: '',
                    potPointsSortOrder_secondTab: '',
                    potDateSortOrder_secondTab: '',
                    amountSortOrder_firstTab: currentOrder !== 'asc' ? 'asc' : 'dsc'
                }
                break;
            case 'potPoints_firstTab':
                currentOrder = this.state.potPointsSortOrder_firstTab;
                newState = {
                    ...this.state,
                    loading: true,
                    rezNumberSortOrder_firstTab: '',
                    amountSortOrder_firstTab: '',
                    potDateSortOrder_firstTab: '',
                    rezNumberSortOrder_secondTab: '',
                    amountSortOrder_secondTab: '',
                    potPointsSortOrder_secondTab: '',
                    potDateSortOrder_secondTab: '',
                    potPointsSortOrder_firstTab: currentOrder !== 'asc' ? 'asc' : 'dsc'
                }
                break;
            case 'potDate_firstTab':
                currentOrder = this.state.potDateSortOrder_firstTab;
                newState = {
                    ...this.state,
                    loading: true,
                    rezNumberSortOrder_firstTab: '',
                    amountSortOrder_firstTab: '',
                    potPointsSortOrder_firstTab: '',
                    rezNumberSortOrder_secondTab: '',
                    amountSortOrder_secondTab: '',
                    potPointsSortOrder_secondTab: '',
                    potDateSortOrder_secondTab: '',
                    potDateSortOrder_firstTab: currentOrder !== 'asc' ? 'asc' : 'dsc'
                }
                break;

            case 'rezNumber_secondTab':
                currentOrder = this.state.rezNumberSortOrder_secondTab;
                newState = {
                    ...this.state,
                    loading: true,
                    rezNumberSortOrder_firstTab: '',
                    amountSortOrder_firstTab: '',
                    potPointsSortOrder_firstTab: '',
                    potDateSortOrder_firstTab: '',
                    amountSortOrder_secondTab: '',
                    potPointsSortOrder_secondTab: '',
                    potDateSortOrder_secondTab: '',
                    rezNumberSortOrder_secondTab: currentOrder !== 'asc' ? 'asc' : 'dsc'
                }
                break;
            case 'amount_secondTab':
                currentOrder = this.state.amountSortOrder_secondTab;
                newState = {
                    ...this.state,
                    loading: true,
                    rezNumberSortOrder_firstTab: '',
                    amountSortOrder_firstTab: '',
                    potPointsSortOrder_firstTab: '',
                    potDateSortOrder_firstTab: '',
                    rezNumberSortOrder_secondTab: '',
                    potPointsSortOrder_secondTab: '',
                    potDateSortOrder_secondTab: '',
                    amountSortOrder_secondTab: currentOrder !== 'asc' ? 'asc' : 'dsc'
                }
                break;
            case 'potPoints_secondTab':
                currentOrder = this.state.potPointsSortOrder_secondTab;
                newState = {
                    ...this.state,
                    loading: true,
                    rezNumberSortOrder_firstTab: '',
                    amountSortOrder_firstTab: '',
                    potPointsSortOrder_firstTab: '',
                    potDateSortOrder_firstTab: '',
                    rezNumberSortOrder_secondTab: '',
                    amountSortOrder_secondTab: '',
                    potDateSortOrder_secondTab: '',
                    potPointsSortOrder_secondTab: currentOrder !== 'asc' ? 'asc' : 'dsc'
                }
                break;
            case 'potDate_secondTab':
                currentOrder = this.state.potDateSortOrder_secondTab;
                newState = {
                    ...this.state,
                    loading: true,
                    rezNumberSortOrder_firstTab: '',
                    amountSortOrder_firstTab: '',
                    potPointsSortOrder_firstTab: '',
                    potDateSortOrder_firstTab: '',
                    rezNumberSortOrder_secondTab: '',
                    amountSortOrder_secondTab: '',
                    potPointsSortOrder_secondTab: '',
                    potDateSortOrder_secondTab: currentOrder !== 'asc' ? 'asc' : 'dsc'
                }
                break;
        }

        this.setState(newState, this.refreshData);
    }

    handleClickSearch = () => {
        this.setState({ ...this.state, loading: true }, this.refreshData)
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.setState({ ...this.state, loading: true }, this.refreshData)
        }
    }

    refreshData = () => {
        this.populateData(true)
    }

    setFromDate = (date) => {
        const firstTab = this.state.firstTabOpened;
        firstTab
            ? this.setState({ ...this.state, dateFrom_firstTab: date, loading: true }, this.refreshData)
            : this.setState({ ...this.state, dateFrom_secondTab: date, loading: true }, this.refreshData)
    }

    setToDate = (date) => {
        const firstTab = this.state.firstTabOpened;
        firstTab
            ? this.setState({ ...this.state, dateTo_firstTab: date, loading: true }, this.refreshData)
            : this.setState({ ...this.state, dateTo_secondTab: date, loading: true }, this.refreshData)
    }

    handleRezNumberChange = (search) => {
        const firstTab = this.state.firstTabOpened;
        firstTab
            ? this.setState({ ...this.state, rezNumberSearch_firstTab: search })
            : this.setState({ ...this.state, rezNumberSearch_secondTab: search })
    }

    renderSortArrow = (column) => {
        return (
            column === ''
                ? <FaArrowsAltV style={{ color: "#808085" }} />
                : column === 'asc'
                    ? <FaLongArrowAltDown style={{ color: "black" }} />
                    : <FaLongArrowAltUp style={{ color: "black" }} />
        )
    }

    generateToolipText = (reservation) => {
        var text = `<span class="font-bold">${i18n.t('special offer points')}`;
        if (reservation.promotionCode) {
            text += '  <br/>' + reservation.promotionCode;
        }

        if (reservation.promotionStart && reservation.promotionEnd) {
            text += ` ${i18n.t('within')} ${new Date(reservation.promotionStart).toLocaleDateString()} – ${new Date(reservation.promotionEnd).toLocaleDateString()}`;
        }

        return text + '</span>';
    }

    renderReservations(firstTableView, secondTableView) {

        firstTableView = firstTableView?.map(element => ({ ...element, tooltipText: this.generateToolipText(element) }))
        secondTableView = secondTableView?.map(element => ({ ...element, tooltipText: this.generateToolipText(element) }))

        return (
            <div className="list-wrapper">
                <div className="d-flex flex-row justify-content-between">
                    <div className={this.state.firstTabOpened ? "table-tab-header-active text-begin" : "table-tab-header text-begin"}
                        onClick={this.activateFirstTab}>
                        {i18n.t('counted point list')}
                    </div>
                    <div className="separator" />
                    <div className={this.state.secondTabOpened ? "table-tab-header-active text-begin" : "table-tab-header text-begin"}
                        onClick={this.activateSecondTab}>
                        {i18n.t('potential point list')}
                    </div>
                </div>
                <div>
                    <table className="mt-30">
                        <thead>
                            <tr>
                                <th className="pl-28 wp-25">
                                    <div>
                                        <span className="table-header" onClick={() => { this.state.firstTabOpened ? this.sortColumn('rezNumber_firstTab') : this.sortColumn('rezNumber_secondTab') }}>
                                        {i18n.t('booking number')}
                                        {this.state.firstTabOpened ? this.renderSortArrow(this.state.rezNumberSortOrder_firstTab) : this.renderSortArrow(this.state.rezNumberSortOrder_secondTab)}
                                        </span>
                                        <div className="relative-wrapper table-input-wrapper">
                          <input value={this.state.firstTabOpened ? this.state.rezNumberSearch_firstTab : this.state.rezNumberSearch_secondTab}
                                                className="table-header-input w-125"
                                                placeholder={i18n.t("type_")}
                                                onChange={(e) => { this.handleRezNumberChange(e.target.value) }}
                                                onKeyDown={this.handleKeyDown}
                                            />
                                            <AiOutlineSearch onClick={() => this.handleClickSearch()} />
                                        </div>
                                    </div>
                                </th>
                                <th className="table-header wp-25">
                                    <div className="d-flex align-items-end" onClick={() => { this.state.firstTabOpened ? this.sortColumn('amount_firstTab') : this.sortColumn('amount_secondTab') }}>
                                    {i18n.t('amount')}  {this.state.firstTabOpened ? this.renderSortArrow(this.state.amountSortOrder_firstTab) : this.renderSortArrow(this.state.amountSortOrder_secondTab)}
                                    </div> </th>
                                <th className="text-center table-header wp-25" onClick={() => { this.state.firstTabOpened ? this.sortColumn('potPoints_firstTab') : this.sortColumn('potPoints_secondTab') }}>
                      {this.state.firstTabOpened ? i18n.t("accured points") : i18n.t("potential points")}
                                    {this.state.firstTabOpened ? this.renderSortArrow(this.state.potPointsSortOrder_firstTab) : this.renderSortArrow(this.state.potPointsSortOrder_secondTab)}
                                </th>
                                <th className="wp-25">
                                    <div>
                                        <span className="table-header" onClick={() => { this.state.firstTabOpened ? this.sortColumn('potDate_firstTab') : this.sortColumn('potDate_secondTab') }}>
                                        {i18n.t('count point date')}
                                        {this.state.firstTabOpened ? this.renderSortArrow(this.state.potDateSortOrder_firstTab) : this.renderSortArrow(this.state.potDateSortOrder_secondTab)}
                                        </span>
                                        <div className="relative-wrapper table-input-wrapper">
                                            <div className="d-flex flex-row">
                                                <div className="datepicker-wrapper">
                                                    <DatePicker
                                                        placeholderText={i18n.t('from')}
                                                        locale={i18n.t('locale')}
                                                        dateFormat="dd.MM.yyyy"
                                                        selected={this.state.firstTabOpened ? this.state.dateFrom_firstTab : this.state.dateFrom_secondTab}
                                                        onChange={(date) => this.setFromDate(date)}
                                                        yearDropdownItemNumber={250}
                                                        scrollableYearDropdown={true}
                                                        showYearDropdown={true}
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                        }} />
                                                </div>
                                                <div className="datepicker-wrapper">
                                                    <DatePicker
                                                        placeholderText={i18n.t('to')}
                                                        locale={i18n.t('locale')}
                                                        dateFormat="dd.MM.yyyy"
                                                        selected={this.state.firstTabOpened ? this.state.dateTo_firstTab : this.state.dateTo_secondTab}
                                                        onChange={(date) => this.setToDate(date)}
                                                        yearDropdownItemNumber={250}
                                                        scrollableYearDropdown={true}
                                                        showYearDropdown={true}
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                        }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {firstTableView.map((reservation, index) => (
                                <tr className="main-table" key={index}>
                                    <td className="pl-28">{reservation.reservationNumber}</td>
                                    <td>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: i18n.t('currency') }).format(reservation.amount).replace(".", " ")}</td>
                                    <td className={reservation.fromPromotion ? "text-center text-green" : "text-center"}>
                                        {reservation.points} {reservation.fromPromotion ? <AiFillStar data-tip={reservation.tooltipText} /> : null}
                                    </td>
                                    <td>{reservation.pointsDate}</td>
                                    <ReactTooltip type="light" html={true} />
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="horizontal-separator" />
                    {
                        secondTableView?.length
                            ? <div className="table-separator">
                                {i18n.t('expired unused')}
                            </div>
                            : null
                    }

                    <table>
                        <tbody>
                            {secondTableView?.map((reservation, index) => (
                                <tr className="secondary-table" key={index}>
                                    <td className="pl-28">{reservation.reservationNumber}</td>
                                    <td>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: i18n.t('currency') }).format(reservation.amount).replace(".", " ")}</td>
                                    <td className={reservation.fromPromotion ? "text-center text-green" : "text-center"}>
                                        {reservation.points} {reservation.fromPromotion ? <AiFillStar data-tip={reservation.tooltipText} /> : null}
                                    </td>
                                    <td>{reservation.pointsDate}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    renderLoader() {
        return (
            <div className="center-table-cell baner-wrapper-no-padding">
                <Audio
                    height="50"
                    width="50"
                    color='grey'
                    ariaLabel='loading'
                    wrapperClass='d-block'
                />
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? this.renderLoader()
            : this.renderReservations(this.state.firstTableView, this.state.secondTableView);

        return (
            <div>
                {contents}
            </div>
        );
    }

    prepareCriteria = () => {
        var dateFrom_earned_local = new Date(this.state.dateFrom_firstTab);
        var dateTo_earned_local = new Date(this.state.dateTo_firstTab);
        var dateFrom_potential_local = new Date(this.state.dateFrom_secondTab);
        var dateTo_potential_local = new Date(this.state.dateTo_secondTab);

        dateFrom_earned_local.setTime(dateFrom_earned_local.getTime() + 2 * 60 * 60 * 1000);
        dateTo_earned_local.setTime(dateTo_earned_local.getTime() + 2 * 60 * 60 * 1000)
        dateFrom_potential_local.setTime(dateFrom_potential_local.getTime() + 2 * 60 * 60 * 1000)
        dateTo_potential_local.setTime(dateTo_potential_local.getTime() + 2 * 60 * 60 * 1000)

        return {
            earnedPoints: {
                rezNumberSortOrder: this.state.rezNumberSortOrder_firstTab,
                amountSortOrder: this.state.amountSortOrder_firstTab,
                pointsSortOrder: this.state.potPointsSortOrder_firstTab,
                dateSortOrder: this.state.potDateSortOrder_firstTab,
                rezNumberSearch: this.state.rezNumberSearch_firstTab,
                dateFromSearch: dateFrom_earned_local,
                dateToSearch: dateTo_earned_local,
            },
            potentialPoints: {
                rezNumberSortOrder: this.state.rezNumberSortOrder_secondTab,
                amountSortOrder: this.state.amountSortOrder_secondTab,
                pointsSortOrder: this.state.potPointsSortOrder_secondTab,
                dateSortOrder: this.state.potDateSortOrder_secondTab,
                rezNumberSearch: this.state.rezNumberSearch_secondTab,
                dateFromSearch: dateFrom_potential_local,
                dateToSearch: dateTo_potential_local,
            }
        }
    }

    async populateData(sendCriteria) {
        const token = await authService.getAccessToken();
        const jsonData = sendCriteria ? this.prepareCriteria() : { model: {} };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(jsonData)
        };

        fetch('reservations', requestOptions)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    reservations: data,
                    firstTableView: this.state.firstTabOpened ? data.earnedPoints : data.potentialPoints,
                    secondTableView: this.state.firstTabOpened ? data.expiredEarnedPoints : [], // There is no expired potential poitns
                    loading: false
                })
            });
    }
}
