
import React, { useState } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri'
import i18n from 'i18next';

export const CustomSelect = (props) => {

    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(props.selectedOption);

    const optionsList = [
        i18n.t("missing_"),
        i18n.t("new"),
        i18n.t("special offer"),
    ];

    const toggleOptions = () => {
        setIsOptionsOpen(!isOptionsOpen);
    };

    const setSelectedThenCloseDropdown = (index) => {
        setSelectedOption(index);
        setIsOptionsOpen(false);
        props.setSelectedOption(index);
    };

    const handleKeyDown = (index) => (e) => {
        switch (e.key) {
            case " ":
            case "SpaceBar":
            case "Enter":
                e.preventDefault();
                setSelectedThenCloseDropdown(index);
                break;
            default:
                break;
        }
    };

    const handleListKeyDown = (e) => {
        switch (e.key) {
            case "Escape":
                e.preventDefault();
                setIsOptionsOpen(false);
                break;
            case "ArrowUp":
                e.preventDefault();
                setSelectedOption(
                    selectedOption - 1 >= 0 ? selectedOption - 1 : optionsList.length - 1
                );
                break;
            case "ArrowDown":
                e.preventDefault();
                setSelectedOption(
                    selectedOption == optionsList.length - 1 ? 0 : selectedOption + 1
                );
                break;
            default:
                break;
        }
    };

    return (
        <div className="select-container">
            <button
                type="button"
                aria-haspopup="listbox"
                aria-expanded={isOptionsOpen}
                className={isOptionsOpen ? "select-button expanded" : "select-button"}
                onClick={toggleOptions}
                onKeyDown={handleListKeyDown}>
                <div className="d-flex flex-row justify-content-between">
                    <div className={selectedOption == 0 ? "option-empty" : selectedOption == 1 ? "option-new" : selectedOption == 2 ? "option-promo" : ""}>
                        {optionsList[selectedOption]}
                    </div>
                    <div>
                        <RiArrowDropDownLine />
                    </div>
                </div>
            </button>
            <ul
                className={`options ${isOptionsOpen ? "show" : ""}`}
                aria-activedescendant={optionsList[selectedOption]}
                onKeyDown={handleListKeyDown}>
                <li aria-selected={selectedOption == 0}
                    onKeyDown={handleKeyDown(0)}
                    onClick={() => { setSelectedThenCloseDropdown(0); }}>
                    <div className="option-empty">
                    {i18n.t('missing_')}
                        </div>
                </li>
                <li aria-selected={selectedOption == 1}
                    onKeyDown={handleKeyDown(1)}
                    onClick={() => { setSelectedThenCloseDropdown(1); }}>
                    <div className="option-new">
                    {i18n.t('new')}
                        </div>
                </li>
                <li aria-selected={selectedOption == 2}
                    onKeyDown={handleKeyDown(2)}
                    onClick={() => { setSelectedThenCloseDropdown(2); }}>
                    <div className="option-promo">
                    {i18n.t('special offer')}
                        </div>
                </li>
            </ul>
        </div>
    );
};
