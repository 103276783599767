import React, { useState } from 'react';
import '../custom.css';
import { GrClose } from 'react-icons/gr';
import i18n from 'i18next';

const GenericInfoPopup = (props) => {
    return (
        <div className='popup'>
            <div className='popup-open d-flex flex-column'>
                <div className="d-flex flex-row justify-content-between" style={{ marginBottom: "30px" }}>
                    <div className="popup-header">
                        {props.header ?? i18n.t('choice of reward')}
                    </div>
                    <div className="popup-close-icon-wrapper d-flex">
                        <GrClose role="button" onClick={props.closePopup} />
                    </div>
                </div>

                <div className="popup-content d-flex flex-column">
                    {props.infoText}
                </div>

                <div className="d-flex flex-row mt-auto justify-content-end">
                    <div>
                        <button className="finish-action-btn button button-red" onClick={props.closePopup}>
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GenericInfoPopup;