import React, { useState } from 'react';
import '../custom.css';
import { GrClose } from 'react-icons/gr';
import RewardForm from './FormDetailsPopup_RewardForm';
import PITForm from './FormDetailsPopup_PitForm';
import { useLocation } from 'react-router-dom';
import i18n from 'i18next';

const RenderActionButtons = (props, isAdmin, canAccept, inputs, bothAdressesTheSame, taxtOffice, agentDoB) => {
    return (
        <div className="d-flex flex-row mt-auto justify-content-end">
            <div >
                <button className="finish-action-btn button button-transparent mr6" onClick={props.closePopup}>
                {i18n.t('cancel')}
                </button>
            </div>
            <div>
                <button className="finish-action-btn button button-grey mr6" onClick={() => props.secondaryAction(getValues(props, inputs, bothAdressesTheSame, taxtOffice, agentDoB))} disabled={false}>
                    {
                        isAdmin ? i18n.t('reject') : i18n.t('save and close')
                    }

                </button>
            </div>
            <div>
                <button className="finish-action-btn button button-red mr6" onClick={() => props.mainAction(getValues(props, inputs, bothAdressesTheSame, taxtOffice, agentDoB))} disabled={!isAdmin && !canAccept}>
                    {
                        isAdmin ? i18n.t('accept_') : i18n.t('send')
                    }
                </button>
            </div>
        </div>
    );
}

const useTextInput = (initValue, disabled, changeCallback, validationCallback) => {
    const [value, setValue] = useState(initValue);
    const validationError = validationCallback ? validationCallback(value) : '';

    const handleValueChange = (value) => {
        setValue(value);
        changeCallback?.(value);
    }

    const input = <div className="d-flex flex-column">
        <input value={value} onChange={e => handleValueChange(e.target.value)} type="text" className={"form-input " + (validationError ? "form-input-invalid" : "")} disabled={disabled} />
        {validationError ? <span className="target-points text-red">{validationError}</span> : null}
    </div>;
    return { value: value, input: input, valid: !validationError };
}

const useNumericTextInput = (initValue, disabled, changeCallback, validationCallback) => {
    const [value, setValue] = useState(initValue);
    const validationError = validationCallback ? validationCallback(value) : '';

    const handleValueChange = (value) => {
        setValue(value);
        changeCallback?.(value);
    }

    const input = <div className="d-flex flex-column">
        <input type="number" value={value} onChange={e => handleValueChange(e.target.value)} className={"form-input " + (validationError ? "form-input-invalid" : "")} disabled={disabled} />
        {validationError ? <span className="target-points text-red">{validationError}</span> : null}
    </div>;
    return { value: value, input: input, valid: !validationError };
}

const getValues = (props, inputs, bothAdressesTheSame, taxtOffice, agentDoB) => {
    let totalSupplement = !isNaN(+inputs.totalSupplement.value?.replace(",", "."))
        ? inputs.totalSupplement.value?.replace(",", ".")
        : 0

    let result = {
        id: props.formDetails.id,
        agentEmail: inputs.agentEmail.value,
        agentPesel: inputs.agentPesel.value,
        agentPhone: inputs.agentPhone.value,
        reservationNumber: inputs.reservationNumber.value > 0 ? inputs.reservationNumber.value : undefined,
        agentDoB: agentDoB,
        taxOfficeCode: taxtOffice.value?.toString(),
        externalPointsAmount: inputs.externalPointsAmount.value,
        externalPointsAgentNumber: inputs.externalPointsAgentNumber.value,
        totalSupplement: totalSupplement,

        registeredAddress: {
            appartment: inputs.registeredAddressAppartment.value,
            building: inputs.registeredAddressBuilding.value,
            city: inputs.registeredAddressCity.value,
            community: inputs.registeredAddressCommunity.value,
            country: inputs.registeredAddressCountry.value,
            post: inputs.registeredAddressPost.value,
            province: inputs.registeredAddressProvince.value,
            street: inputs.registeredAddressStreet.value,
            zipCode: inputs.registeredAddressZipCode.value,
            county: inputs.registeredAddressCounty.value,
        }
    }

    if (bothAdressesTheSame) {
        result.correspondenceAddress = result.registeredAddress;
        result.correspondenceAddressSameAsRegistered = true;
    } else {
        result.correspondenceAddress = {
            appartment: inputs.correspondenceAddressAppartment.value,
            building: inputs.correspondenceAddressBuilding.value,
            city: inputs.correspondenceAddressCity.value,
            community: inputs.correspondenceAddressCommunity.value,
            country: inputs.correspondenceAddressCountry.value,
            post: inputs.correspondenceAddressPost.value,
            province: inputs.correspondenceAddressProvince.value,
            street: inputs.correspondenceAddressStreet.value,
            zipCode: inputs.correspondenceAddressZipCode.value,
            county: inputs.correspondenceAddressCounty.value,
        }
    }
    return result;
}

const FormDetailsPopup = (props) => {
    let location = useLocation();
    let isAdmin = location?.state?.isAdmin;

    const emailValidator = (email) => {
        if (!email) {
            return i18n.t('field required');
        }

        if (!String(email)
            .toLowerCase()
            .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            return i18n.t('incorrect email');
        }

        return '';
    }

    const pointsValidator = (points) => {
        if (!points) {
            return '';
        }

        if (!/^[0-9]+$/.test(points)) {
            return i18n.t('digit only');
        }
    }

    const moneyValidator = (money) => {
        if (!money && money !== 0) {
            return i18n.t('field required');
        }

        if (!/^\d+\,?\d{0,2}$/.test(money)) {
            return i18n.t('incorrect amount');
        }
    }

    const phoneValidator = (phone) => {
        if (!phone) {
            return i18n.t('field required');
        }

        // if (!/^[0-9]+$/.test(phone) || phone.length < 9) {
        //     return i18n.t('phone to short');
        // }

        return '';
    }

    const peselValidator = (pesel) => {
        if (!pesel) {
            return i18n.t('field required');
        }

        // if (pesel.length > 0 && (!/^[0-9]+$/.test(pesel) || pesel.length != 11)) {
        //     return i18n.t('pesel to short');
        // }

        return '';
    }

    const rezNumberValidator = (rezNumber) => {
        if (!rezNumber) {
            return i18n.t('field required');
        }

        if (!/^[0-9]+$/.test(rezNumber) || rezNumber.length < 7) {
            return i18n.t('number to short');
        }

        if (props.formDetails.invalidReservation) {
            return i18n.t('number incorrect');
        }

        return '';
    }

    const requiredValidator = (value) => {
        if (!value) {
            return i18n.t('field required');
        }

        return '';
    }

    const locationTaxRequiredValidator = (value) => {
        if (i18n.t('taxValidation') === "false") {
            return '';
        }

        if (!value) {
            return i18n.t('field required');
        }

        return '';
    }

    const rewardInputs = {
        agentEmail: useTextInput(props.formDetails.agentEmail, isAdmin, null, emailValidator),
        agentPhone: useTextInput(props.formDetails.agentPhone, isAdmin, null, phoneValidator),
        externalPointsAgentNumber: useTextInput(props.formDetails.externalPointsAgentNumber, isAdmin),
        externalPointsAmount: useNumericTextInput(props.formDetails.externalPointsAmount, isAdmin, null, pointsValidator),

        reservationNumber: useTextInput(props.formDetails.reservationNumber, isAdmin, props.onRezNumberChange, rezNumberValidator),
    }

    const pitInputs = {
        agentPesel: useTextInput(props.formDetails.agentPesel, isAdmin, null, peselValidator),

        registeredAddressStreet: useTextInput(props.formDetails.registeredAddress?.street, isAdmin, null, locationTaxRequiredValidator),
        registeredAddressBuilding: useTextInput(props.formDetails.registeredAddress?.building, isAdmin, null, locationTaxRequiredValidator),
        registeredAddressAppartment: useTextInput(props.formDetails.registeredAddress?.appartment, isAdmin),
        registeredAddressZipCode: useTextInput(props.formDetails.registeredAddress?.zipCode, isAdmin, null, locationTaxRequiredValidator),
        registeredAddressCity: useTextInput(props.formDetails.registeredAddress?.city, isAdmin, null, locationTaxRequiredValidator),
        registeredAddressPost: useTextInput(props.formDetails.registeredAddress?.post, isAdmin, null, locationTaxRequiredValidator),
        registeredAddressCountry: useTextInput(props.formDetails.registeredAddress?.country, isAdmin, null, locationTaxRequiredValidator),
        registeredAddressProvince: useTextInput(props.formDetails.registeredAddress?.province, isAdmin, null, locationTaxRequiredValidator),
        registeredAddressCounty: useTextInput(props.formDetails.registeredAddress?.county, isAdmin, null, locationTaxRequiredValidator),
        registeredAddressCommunity: useTextInput(props.formDetails.registeredAddress?.community, isAdmin, null, locationTaxRequiredValidator),

        correspondenceAddressStreet: useTextInput(props.formDetails.correspondenceAddress?.street, isAdmin, null, locationTaxRequiredValidator),
        correspondenceAddressBuilding: useTextInput(props.formDetails.correspondenceAddress?.building, isAdmin, null, locationTaxRequiredValidator),
        correspondenceAddressAppartment: useTextInput(props.formDetails.correspondenceAddress?.appartment, isAdmin),
        correspondenceAddressZipCode: useTextInput(props.formDetails.correspondenceAddress?.zipCode, isAdmin, null, locationTaxRequiredValidator),
        correspondenceAddressCity: useTextInput(props.formDetails.correspondenceAddress?.city, isAdmin, null, locationTaxRequiredValidator),
        correspondenceAddressPost: useTextInput(props.formDetails.correspondenceAddress?.post, isAdmin, null, locationTaxRequiredValidator),
        correspondenceAddressCountry: useTextInput(props.formDetails.correspondenceAddress?.country, isAdmin, null, locationTaxRequiredValidator),
        correspondenceAddressProvince: useTextInput(props.formDetails.correspondenceAddress?.province, isAdmin, null, locationTaxRequiredValidator),
        correspondenceAddressCounty: useTextInput(props.formDetails.correspondenceAddress?.county, isAdmin, null, locationTaxRequiredValidator),
        correspondenceAddressCommunity: useTextInput(props.formDetails.correspondenceAddress?.community, isAdmin, null, locationTaxRequiredValidator),

        totalSupplement: useTextInput(props.formDetails.totalSupplement?.toString().replace(".", ","), isAdmin, null, moneyValidator),
    }

    let addressesTheSame = props.formDetails.correspondenceAddressSameAsRegistered === undefined ? true : props.formDetails.correspondenceAddressSameAsRegistered;

    const [firstTabOpened, setFirstTabOpened] = useState(true);
    const [secondTabOpened, setSecondTabOpened] = useState(false);
    const [bothAdressesTheSame, setBothAdressesTheSame] = useState(addressesTheSame);
    const [approveForm, setApproveForm] = useState(false);
    const [taxtOffice, setTaxOffice] = useState({});
    const [agentDoB, setAgentDoB] = useState(undefined);

    const activateFirstTab = () => {
        setFirstTabOpened(true);
        setSecondTabOpened(false);
    };

    const activateSecondTab = () => {
        setFirstTabOpened(false);
        setSecondTabOpened(true);
    };

    let showTaxData = i18n.t('locale') === "pl";

    return (
        <div className='popup'>
            <div className='popup-open-form d-flex flex-column'>
                <div className="d-flex flex-row justify-content-between" style={{ marginBottom: "30px" }}>
                    <div className="d-flex flex-row justify-content-between" style={{ width: "70%" }}>
                        <div className={firstTabOpened ? "form-popup-header-active text-begin" : "form-popup-header text-begin"}
                            onClick={activateFirstTab}>
                            {i18n.t('agreement form')}
                        </div>
                        <div className="separator" />
                        <div className={secondTabOpened ? "form-popup-header-active text-begin" : "form-popup-header text-begin"}
                            onClick={activateSecondTab}>
                            {i18n.t('tax form')}
                        </div>
                    </div>

                    <div className="popup-close-icon-wrapper d-flex">
                        <GrClose role="button" onClick={props.closePopup} />
                    </div>
                </div>

                <div className="popup-content d-flex flex-column">
                    <div className="form-details-content">
                        {firstTabOpened
                            ? <RewardForm {...props}
                                rewardInputs={rewardInputs} />
                            : <PITForm {...props}
                                taxOffice={taxtOffice}
                                isAdmin={isAdmin}
                                agentDoB={agentDoB}
                                taxOfficeSelected={setTaxOffice}
                                pitInputs={pitInputs}
                                bothAdressesTheSame={bothAdressesTheSame}
                                setBothAdressesTheSame={setBothAdressesTheSame}
                                approveForm={approveForm}
                                setApproveForm={setApproveForm}
                                setAgentDoB={setAgentDoB}
                            />
                        }
                    </div>
                </div>
                {!isAdmin || props.formDetails.status == 0 ? RenderActionButtons(props,
                    isAdmin,
                    showTaxData ?
                        (approveForm
                        && props.formDetails.reservationDownloaded
                        && rewardInputs.agentEmail.valid
                        && rewardInputs.agentPhone.valid
                        && pitInputs.totalSupplement.valid
                        && pitInputs.agentPesel.valid
                        && pitInputs.registeredAddressStreet.valid
                        && pitInputs.registeredAddressBuilding.valid
                        && pitInputs.registeredAddressZipCode.valid
                        && pitInputs.registeredAddressCity.valid
                        && pitInputs.registeredAddressPost.valid
                        && pitInputs.registeredAddressCountry.valid
                        && pitInputs.registeredAddressProvince.valid
                        && pitInputs.registeredAddressCounty.valid
                        && pitInputs.registeredAddressCommunity.valid
                        &&
                        (bothAdressesTheSame ||
                          (pitInputs.correspondenceAddressStreet.valid
                            && pitInputs.correspondenceAddressBuilding.valid
                            && pitInputs.correspondenceAddressZipCode.valid
                            && pitInputs.correspondenceAddressCity.valid
                            && pitInputs.correspondenceAddressPost.valid
                            && pitInputs.correspondenceAddressCountry.valid
                            && pitInputs.correspondenceAddressProvince.valid
                            && pitInputs.correspondenceAddressCounty.valid
                            && pitInputs.correspondenceAddressCommunity.valid)
                        )
                        && rewardInputs.externalPointsAmount.valid
                        && (taxtOffice?.value || props.formDetails.taxOfficeCode)
                        && props.formDetails.percentageProgress >= i18n.t("#requiredPoints"))
                      :
                        (approveForm
                        && props.formDetails.reservationDownloaded
                        && rewardInputs.agentEmail.valid
                        && rewardInputs.agentPhone.valid
                        && pitInputs.totalSupplement.valid
                        && pitInputs.agentPesel.valid                      
                        && rewardInputs.externalPointsAmount.valid
                        && props.formDetails.percentageProgress >= i18n.t("#requiredPoints")),

                    { ...pitInputs, ...rewardInputs },
                    bothAdressesTheSame,
                    taxtOffice,
                    agentDoB
                )
                    : null
                }

                {props.requestInProgress && <div className="overlay" />}
            </div>
        </div>
    );
}

export default FormDetailsPopup;