import React, { useState, useRef } from 'react';
import '../../../custom.css';
import { GrClose } from 'react-icons/gr';
import i18n from 'i18next';

const RenderActionButtons = (props) => {
    return (
        <div className="d-flex flex-row mt-auto justify-content-end">
            <div >
                <button className="finish-action-btn button button-transparent mr6" onClick={props.closePopup}>
                {i18n.t('cancel')}
                </button>
            </div>
            <div>
                <button className="finish-action-btn button button-red" onClick={() => props.remove()}>
                {i18n.t('yes')}
                 </button>
            </div>
        </div>
    );
}

const RemoveUserPopup = (props) => {
    return (
        <div className='popup'>
            <div className='popup-open d-flex flex-column'>
                <div className="d-flex flex-row justify-content-between" style={{ marginBottom: "30px" }}>
                    <div className="popup-header">
                    {i18n.t('remove user warning')}
                    </div>
                    <div className="popup-close-icon-wrapper d-flex" style={{height: "33px"}}>
                        <GrClose role="button" onClick={props.closePopup} />
                    </div>
                </div>

                <div className="popup-content d-flex flex-column add-note-content">
                {i18n.t('suspend user warning')}
                </div>

                {RenderActionButtons(props)}
            </div>
        </div>
    );
}

export default RemoveUserPopup;