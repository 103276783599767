import React, { useState } from 'react';
import '../custom.css';
import { GrClose } from 'react-icons/gr';
import { SortSelect } from './SortSelect'
import { AiOutlineSearch } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';
import i18n from 'i18next';

const RenderActionButtons = (props) => {
    return (
        <div className="d-flex flex-row mt-auto justify-content-end">
            <div >
                <button className="finish-action-btn button button-grey mr6" onClick={props.closePopup}>
                {i18n.t('cancel')}
                 </button>
            </div>
            <div>
                <button className="finish-action-btn button button-red" onClick={() => props.saveReward()} disabled={props.rewards?.rewards.every(reward => !reward.isMarked)}>
                {i18n.t('save')}
                 </button>
            </div>
        </div>
    );
}

const SelectRewardsListPopup = (props) => {

    const optionsList = [
        i18n.t("alphabeticaly"),
        i18n.t("points asc"),
        i18n.t("points desc"),
    ];

    return (
        <div className='popup'>
            <div className='popup-open reward-popup-open d-flex flex-column'>
                <div className="d-flex flex-row justify-content-between">
                    <div className={props.updateRealizeNoteResponse ? "popup-header text-green" : "popup-header"}>
                        {props.rewards?.rewardsListName}
                    </div>
                    <div className="popup-close-icon-wrapper d-flex">
                        <GrClose role="button" onClick={props.closePopup} />
                    </div>
                </div>
                <span className="target-points">{i18n.t('last update')} {new Date(props.rewards?.lastUpdateDate).toLocaleDateString()}</span>

                <div className="d-flex flex-row select-rewards-points" style={{ marginBottom: "20px", marginTop: "20px" }}>
                    <span>
                    {i18n.t('your points')} &nbsp;
                    </span>
                    <span className="text-green">
                        {props.userPoints}
                    </span>
                </div>
                <div className="select-rewards-subheader" style={{ marginBottom: "30px" }}>
                {i18n.t('select target')}
                </div>

                <div className="d-flex flex-row justify-content-between" style={{ marginBottom: "20px" }}>
                    <div className="select-reward-search-input-wrapper">
                        <input className="select-reward-search-input" placeholder={i18n.t("search by name or code")} onChange={props.filter} />
                        <AiOutlineSearch />
                    </div>

                    <div className="d-flex flex-row align-items-center">
                        <div className="d-flex sort-select-prefix">{i18n.t('sort')}</div>
                        <div className="d-flex">
                            <SortSelect optionsList={optionsList} setSelectedOption={props.sort} />
                        </div>
                    </div>
                </div>

                <div className="popup-content-reward d-flex flex-column" style={{ marginBottom: "20px" }}>
                    {props.rewards?.rewards.map((reward, index) => (
                        <div className="d-flex flex-row align-items-center reward-list-item" key={index}>
                            <div className="d-flex flex-row align-items-center justify-content-center wp-10">
                                <div className="clickable-circle" onClick={() => props.markReward(reward.id)}>
                                    {
                                        reward.isMarked ? <div className="clickable-inner-circle"></div> : null
                                    }
                                </div>
                            </div>
                            <div className={"d-flex flex-row align-items-center reward-code wp-20 " + (reward.isMarked ? "font-bold" : "")}>{reward.code}</div>
                            <div className="d-flex flex-row align-items-center reward-name wp-45" data-tip={reward.name}>{reward.name}</div>
                            <div className={"d-flex flex-row align-items-center justify-content-end reward-points wp-25 " + (reward.price <= props.userPoints ? "text-green" : "")}>
                                {reward.price.toLocaleString('en').replace(/,/g, " ") + i18n.t("pkt")}
                            </div>
                            <ReactTooltip type="light" html={true} />
                        </div>
                    ))}
                </div>

                {RenderActionButtons(props)}
            </div>

            {props.requestInProgress && <div className="overlay" />}
        </div>
    );
}

export default SelectRewardsListPopup;