import React from 'react';
import '../custom.css';
import { GrClose } from 'react-icons/gr';
import { useFilePicker } from 'use-file-picker';

const ErrorMsg = (props) => {

    return (
        <div className='popup' onClick={props.close}>
            <div className='error-msg-open d-flex flex-column'>
                {props.errorMsg}
            </div>
        </div>
    );
}

export default ErrorMsg;