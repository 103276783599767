import React, { useState } from 'react';
import '../custom.css';
import { GrClose } from 'react-icons/gr';
import DatePicker, { registerLocale } from "react-datepicker";
import i18n from 'i18next';


const RenderCloseButton = (props) => {
    return (
        <div className="d-flex flex-row mt-auto justify-content-end">
            <div>
                <button className="finish-action-btn button button-grey" onClick={props.closePopup}>
                {i18n.t('close')}
                 </button>
            </div>
        </div>
    );
}

const RenderActionButtons = (props, email, joiningDate) => {
    return (
        <div className="d-flex flex-row mt-auto justify-content-end">
            <div >
                <button className="finish-action-btn button button-transparent" onClick={props.closePopup}>
                {i18n.t('cancel')}
                </button>
            </div>
            <div>
                <button className="finish-action-btn button button-red" onClick={() => { props.addMember(email, joiningDate) }} disabled={!email}>
                {i18n.t('save')}
                 </button>
            </div>
        </div>
    );
}

const AddMemberPopup = (props) => {
    const [email, setEmail] = useState('');
    const [joiningDate, setJoiningDate] = useState(new Date());

    const handleEmailChange = event => {
        setEmail(event.target.value);
    };

    const handleJoiningDateChange = date => {
        setJoiningDate(date);
    };

    const popupHeader = props.addMemberResponse ? i18n.t('new member added') : i18n.t('add seller');

    return (
        <div className='popup'>
            <div className='popup-open d-flex flex-column add-user-popup'>
                <div className="d-flex flex-row justify-content-between" style={{ marginBottom: "30px" }}>
                    <div className={props.addMemberResponse ? "popup-header text-green" : "popup-header"}>
                        {popupHeader}
                    </div>
                    <div className="popup-close-icon-wrapper d-flex">
                        <GrClose role="button" onClick={props.closePopup} />
                    </div>
                </div>

                {props.addMemberResponse
                    ? <div className="popup-content d-flex flex-row">
                        <span className="font-bold mr6">{props.addMemberResponse.agencyNo}</span>
                        <span>{props.addMemberResponse.name}</span>
                    </div>
                    : <div className="popup-content d-flex flex-column">
                        <span>{i18n.t('agent seller email')}</span>
                        <input className="baner-input baner-input-header" type="text" onChange={handleEmailChange} />
                        <span>{i18n.t('join date')}</span>
                        <DatePicker
                            yearDropdownItemNumber={250}
                            scrollableYearDropdown={true}
                            showYearDropdown={true}
                            locale={i18n.t('locale')}
                            dateFormat="dd.MM.yyyy"
                            selected={joiningDate}
                            onChange={(date) => handleJoiningDateChange(date)}
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>
                }

                {props.addMemberResponse ? RenderCloseButton(props) : RenderActionButtons(props, email, joiningDate)}
            </div>

            {props.requestInProgress && <div className="overlay" />}
        </div>
    );
}

export default AddMemberPopup;