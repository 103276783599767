import React, { useState, useRef } from 'react';
import '../../../custom.css';
import { GrClose } from 'react-icons/gr';
import { TiAttachment } from 'react-icons/ti';
import i18n from 'i18next';

const RenderActionButtons = (props, noteData) => {
    return (
        <div className="d-flex flex-row mt-auto justify-content-end">
            <div >
                <button className="finish-action-btn button button-transparent mr6" onClick={props.closePopup}>
                {i18n.t('cancel')}
                </button>
            </div>
            <div>
                <button className="finish-action-btn button button-red" onClick={() => props.addNote(noteData)}>
                {i18n.t('generate')}
                 </button>
            </div>
        </div>
    );
}

const RenderFilePicker = (onUploadFileChange) => {
    const myRefname = useRef(null);
    const handleClick = () => {
        myRefname.current.click();
    }

    return (
        <div>
            <input ref={myRefname} type="file" onChange={onUploadFileChange} style={{ display: "none" }} />
            <div className="user-details-action-link" onClick={handleClick}>
                <div className="mr6 d-flex">
                    <TiAttachment />
                </div>
                <div >
                {i18n.t('add attachment')}
                        </div>
            </div>
        </div>

    );
}

const AddNotePopup = (props) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [file, setFile] = useState(null)
    const [fileName, setFileName] = useState(null)

    const fileToBase64 = (file, cb) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
            cb(null, reader.result)
        }
        reader.onerror = function (error) {
            cb(error, null)
        }
    }

    const onUploadFileChange = ({ target }) => {
        if (target.files < 1 || !target.validity.valid) {
            return
        }
        fileToBase64(target.files[0], (err, result) => {
            if (result) {
                setFile(result)
                setFileName(target.files[0].name)
            }
        })
    }

    const noteData = {
        currentDate,
        file,
        fileName
    }

    return (
        <div className='popup'>
            <div className='popup-open d-flex flex-column'>
                <div className="d-flex flex-row justify-content-between" style={{ marginBottom: "30px" }}>
                    <div className="popup-header">
                    {i18n.t('add note')}
                    </div>
                    <div className="popup-close-icon-wrapper d-flex">
                        <GrClose role="button" onClick={props.closePopup} />
                    </div>
                </div>

                <div className="popup-content d-flex flex-column add-note-content">
                    <div className="d-flex flex-row" style={{ marginBottom: "15px" }}>
                        <div className="wp-20">
                        {i18n.t('seller')}
                        </div>
                        <div className="wp-80 font-bold">
                            {props.name}
                        </div>
                    </div>
                    <div className="d-flex flex-row" style={{ marginBottom: "15px" }}>
                        <div className="wp-20">
                        {i18n.t('date')}
                        </div>
                        <div className="wp-80 font-bold">
                            {currentDate.toLocaleString() + ""}
                        </div>
                    </div>
                    <div className="d-flex flex-row">
                        <div className="wp-20">
                        {i18n.t('note content')}
                        </div>
                        <div className="wp-80 font-bold">
                            <textarea className="add-note-textarea"
                                onChange={(e) => props.noteContentChanged(e.target.value)}
                                maxLength="200"
                                placeholder={i18n.t("type")}
                            />
                        </div>
                    </div>
                    <div className="user-details-date" style={{ "textAlign": "end", "marginBottom": "20px" }}>
                    {i18n.t('max 200 chars')}
                    </div>
                    {RenderFilePicker(onUploadFileChange)}
                    {fileName ?
                        <div style={{ "marginBottom": "30px" }}>
                            {i18n.t('selected file')} <span className="font-bold">{fileName}</span>
                        </div>
                        : <div style={{ "marginBottom": "30px" }}>
                        </div>
                    }
                </div>

                {RenderActionButtons(props, noteData)}
            </div>
        </div>
    );
}

export default AddNotePopup;