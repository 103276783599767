import React, { useState } from 'react';
import '../custom.css';
import { FiInfo } from 'react-icons/fi';
import Select from 'react-select'
import DatePicker, { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl";
import i18n from 'i18next';
registerLocale("pl", pl);

const PITForm = (props) => {
    let taxOfficeSelected = props.taxOffice?.value || props.formDetails.taxOfficeCode;

    let customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px',
        }),
        control: (styles) => ({
            ...styles,
            height: '30px',
            background: taxOfficeSelected ? '#FFFFFF' : '#FFCCCB',
            border: taxOfficeSelected ? '1px solid #D8D8D8' : '1px solid #FF0000',
            borderRadius: '3px',
            fontWeight: '400',
            fontSize: '14px',
        }),
    }

    let showTaxData = i18n.t('locale') === "pl";

    const formatMoney = (input) => {
        if (!input) {
            return "-"
        }
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: i18n.t('currency') }).format(input).replace(".", " ");
    }

    return (
        <div className="d-flex flex-column" >
            <div className="form-header">
            {i18n.t('seller data')}
            </div>
            <div className="d-flex flex-row">
                <div className="d-flex flex-column form-input-wrapper" style={{ "width": "80px" }}>
                    <span className="form-input-label">
                    {i18n.t('seller')}
                    </span>
                    <input type="text" className="form-input" value={props.formDetails.agentId} readOnly={true} disabled></input>
                </div>
                <div className="d-flex flex-column form-input-wrapper" style={{ "width": "125px" }}>
                    <span className="form-input-label">
                    {i18n.t('name')}
                    </span>
                    <input type="text" className="form-input" value={props.formDetails.agentFirstName} readOnly={true} disabled></input>
                </div>
                <div className="d-flex flex-column form-input-wrapper" style={{ "flexGrow": "1" }}>
                    <span className="form-input-label">
                    {i18n.t('surname')}
                    </span>
                    <input type="text" className="form-input" value={props.formDetails.agentLastName} readOnly={true} disabled></input>
                </div>
            </div>
            <div className="d-flex flex-row" style={{ "marginTop": "15px" }}>
            {showTaxData ?
                <div className="d-flex flex-column form-input-wrapper" style={{ "width": "50%" }}>
                  <span className="form-input-label">
                    {i18n.t('dob')}
                  </span>
                  <DatePicker
                    yearDropdownItemNumber={250}
                    scrollableYearDropdown={true}
                    showYearDropdown={true}
                    disabled={props.isAdmin}
                    locale={i18n.t('locale')}
                    dateFormat="dd.MM.yyyy"
                    selected={props.formDetails.agentDoB ? new Date(props.formDetails.agentDoB) : props.agentDoB}
                    onChange={(date) => props.setAgentDoB(date)}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                </div> :
            null}
                <div className="d-flex flex-column form-input-wrapper" style={{ "width": "50%" }}>
                    <span className="form-input-label">
                    {i18n.t('pesel')}
                    </span>
                    {props.pitInputs.agentPesel.input}
                </div>
        </div>
        {showTaxData ?
        <div>
            <div className="form-header" style={{ "marginTop": "50px", "marginBottom": "5px" }}>
            {i18n.t('registered address')}
            </div>
            <div className="d-flex flex-row text-center form-info form-input-wrapper" style={{ "marginTop": "0px" }}>
                <FiInfo />
                <div className="form-input-label form-info-label">
                {i18n.t('address should match tax office')}
                </div>
            </div>
            <div className="d-flex flex-row" style={{ "marginTop": "20px" }}>
                <div className="d-flex flex-column form-input-wrapper" style={{ "flexGrow": "1" }}>
                    <span className="form-input-label">
                    {i18n.t('street')}
                    </span>
                    {props.pitInputs.registeredAddressStreet.input}
                </div>
                <div className="d-flex flex-column form-input-wrapper" style={{ "width": "80px" }}>
                    <span className="form-input-label">
                    {i18n.t('building number')}
                    </span>
                    {props.pitInputs.registeredAddressBuilding.input}
                </div>
                <div className="d-flex flex-column form-input-wrapper" style={{ "width": "80px" }}>
                    <span className="form-input-label">
                    {i18n.t('apartment number')}
                    </span>
                    {props.pitInputs.registeredAddressAppartment.input}
                </div>
            </div>
            <div className="d-flex flex-row" style={{ "marginTop": "20px" }}>
                <div className="d-flex flex-column form-input-wrapper" style={{ "width": "80px" }}>
                    <span className="form-input-label">
                    {i18n.t('postal code')}
                    </span>
                    {props.pitInputs.registeredAddressZipCode.input}
                </div>
                <div className="d-flex flex-column form-input-wrapper" style={{ "width": "170px" }}>
                    <span className="form-input-label">
                    {i18n.t('city')}
                    </span>
                    {props.pitInputs.registeredAddressCity.input}
                </div>
                <div className="d-flex flex-column form-input-wrapper" style={{ "flexGrow": "1" }}>
                    <span className="form-input-label">
                    {i18n.t('post')}
                    </span>
                    {props.pitInputs.registeredAddressPost.input}
                </div>
            </div>
            <div className="d-flex flex-row" style={{ "marginTop": "20px" }}>
                <div className="d-flex flex-column form-input-wrapper" style={{ "width": "50%" }}>
                    <span className="form-input-label">
                    {i18n.t('community')}
                    </span>
                    {props.pitInputs.registeredAddressCommunity.input}
                </div>
                <div className="d-flex flex-column form-input-wrapper" style={{ "width": "50%" }}>
                    <span className="form-input-label">
                    {i18n.t('county')}
                    </span>
                    {props.pitInputs.registeredAddressCounty.input}
                </div>
            </div>
            <div className="d-flex flex-row" style={{ "marginTop": "20px" }}>
                <div className="d-flex flex-column form-input-wrapper" style={{ "width": "50%" }}>
                    <span className="form-input-label">
                    {i18n.t('region')}
                    </span>
                    {props.pitInputs.registeredAddressProvince.input}
                </div>
                <div className="d-flex flex-column form-input-wrapper" style={{ "width": "50%" }}>
                    <span className="form-input-label">
                    {i18n.t('country')}
                    </span>
                    {props.pitInputs.registeredAddressCountry.input}
                </div>
          </div>
            <div className="form-header" style={{ "marginTop": "50px", "marginBottom": "15px" }}>
            {i18n.t('corespondence address')}
            </div>
            {
                !props.isAdmin
                    ? <div className="d-flex flex-row align-items-center checkbox-container form-input-wrapper">
                        <div className={props.bothAdressesTheSame ? "custom-checkbox custom-checkbox-active mr6" : "custom-checkbox mr6"} onClick={() => props.setBothAdressesTheSame(!props.bothAdressesTheSame)} />
                        <label className="sub-header">{i18n.t('same as registered')}</label>
                    </div>
                    : <div></div>
            }
            {!props.bothAdressesTheSame || props.isAdmin
                ? <div>
                    <div className="d-flex flex-row" style={{ "marginTop": "20px" }}>
                        <div className="d-flex flex-column form-input-wrapper" style={{ "flexGrow": "1" }}>
                            <span className="form-input-label">
                            {i18n.t('street')}
                            </span>
                            {props.pitInputs.correspondenceAddressStreet.input}
                        </div>
                        <div className="d-flex flex-column form-input-wrapper" style={{ "width": "80px" }}>
                            <span className="form-input-label">
                            {i18n.t('building number')}
                            </span>
                            {props.pitInputs.correspondenceAddressBuilding.input}
                        </div>
                        <div className="d-flex flex-column form-input-wrapper" style={{ "width": "80px" }}>
                            <span className="form-input-label">
                            {i18n.t('apartment number')}
                            </span>
                            {props.pitInputs.correspondenceAddressAppartment.input}
                        </div>
                    </div>
                    <div className="d-flex flex-row" style={{ "marginTop": "20px" }}>
                        <div className="d-flex flex-column form-input-wrapper" style={{ "width": "80px" }}>
                            <span className="form-input-label">
                            {i18n.t('postal code')}
                            </span>
                            {props.pitInputs.correspondenceAddressZipCode.input}
                        </div>
                        <div className="d-flex flex-column form-input-wrapper" style={{ "width": "170px" }}>
                            <span className="form-input-label">
                            {i18n.t('city')}
                            </span>
                            {props.pitInputs.correspondenceAddressCity.input}
                        </div>
                        <div className="d-flex flex-column form-input-wrapper" style={{ "flexGrow": "1" }}>
                            <span className="form-input-label">
                            {i18n.t('post')}
                            </span>
                            {props.pitInputs.correspondenceAddressPost.input}
                        </div>
                    </div>
                    <div className="d-flex flex-row" style={{ "marginTop": "20px" }}>
                        <div className="d-flex flex-column form-input-wrapper" style={{ "width": "50%" }}>
                            <span className="form-input-label">
                            {i18n.t('community')}
                            </span>
                            {props.pitInputs.correspondenceAddressCommunity.input}
                        </div>
                        <div className="d-flex flex-column form-input-wrapper" style={{ "width": "50%" }}>
                            <span className="form-input-label">
                            {i18n.t('county')}
                            </span>
                            {props.pitInputs.correspondenceAddressCounty.input}
                        </div>
                    </div>
                    <div className="d-flex flex-row" style={{ "marginTop": "20px" }}>
                        <div className="d-flex flex-column form-input-wrapper" style={{ "width": "50%" }}>
                            <span className="form-input-label">
                            {i18n.t('region')}
                            </span>
                            {props.pitInputs.correspondenceAddressProvince.input}
                        </div>
                        <div className="d-flex flex-column form-input-wrapper" style={{ "width": "50%" }}>
                            <span className="form-input-label">
                            {i18n.t('country')}
                            </span>
                            {props.pitInputs.correspondenceAddressCountry.input}
                        </div>
                    </div>
                </div>
              : null}
            <div className="form-header" style={{ "marginTop": "50px", "marginBottom": "15px" }}>
              {i18n.t('tax office code')}
            </div>
            <div className="d-flex flex-column">
              <div className="d-flex flex-column form-input-wrapper" style={{ "width": "100%" }}>
                <Select defaultValue={{
                  label: props.formDetails.taxOfficeName ?? props.taxOffice.label,
                  value: props.formDetails.taxOfficeCode ?? props.taxOffice.value
                }}
                  placeholder={"Skarbówka"}
                  isDisabled={props.isAdmin}
                  options={props.taxOffices}
                  styles={customStyles}
                  onChange={props.taxOfficeSelected} />
              </div>
              {
                taxOfficeSelected ? null : <span className="target-points text-red form-input-wrapper">{i18n.t('tax office required')}</span>
              }
            </div>
          </div>
          : null}

            
            <div className="form-header" style={{ "marginTop": "50px" }}>
            {i18n.t('reward data')}
            </div>
            <div className="d-flex flex-row">
                <div className="d-flex flex-column form-input-wrapper" style={{ "width": "120px" }}>
                    <span className="form-input-label">
                    {i18n.t('booking number')}
                    </span>
                    <input type="text" className="form-input" value={props.formDetails.reservationNumber} readOnly={true} disabled></input>
                </div>
            </div>
            <div className="d-flex flex-row" style={{ "marginTop": "20px" }}>
                <div className="d-flex flex-column form-input-wrapper" style={{ "width": "120px" }}>
                    <span className="form-input-label">
                    {i18n.t('agency no')}
                    </span>
                    <input type="text" className="form-input" value={props.formDetails.agencyOfficeNumber} readOnly={true} disabled></input>
                </div>
                <div className="d-flex flex-column form-input-wrapper" style={{ "flexGrow": "1" }}>
                    <span className="form-input-label">
                    {i18n.t('agency name')}
                    </span>
                    <input type="text" className="form-input" value={props.formDetails.agencyOfficeName} readOnly={true} disabled></input>
                </div>
            </div>
            <div className="d-flex flex-row" style={{ "marginTop": "20px" }}>
                <div className="d-flex flex-column form-input-wrapper" style={{ "width": "120px" }}>
                    <span className="form-input-label">
                    {i18n.t('departure date')}
                    </span>
                    <input type="text" className="form-input" value={props.formDetails.departureDate ? new Date(props.formDetails.departureDate).toLocaleDateString() : ""} readOnly={true} disabled></input>
                </div>
                <div className="d-flex flex-column form-input-wrapper" style={{ "flexGrow": "1" }}>
                    <span className="form-input-label">
                    {i18n.t('accommodation')}
                    </span>
                    <input type="text" className="form-input" value={props.formDetails.destination} readOnly={true} disabled></input>
                </div>
            </div>
            <div className="d-flex flex-row" style={{ "marginTop": "20px" }}>
                <div className="d-flex flex-column form-input-wrapper" style={{ "width": "50%" }}>
                    <span className="form-input-label">
                    {i18n.t('booking price')}
                    </span>
                    <input type="text" className="form-input" value={formatMoney(props.formDetails.systemPrice)} readOnly={true} disabled></input>
                </div>
                <div className="d-flex flex-column form-input-wrapper" style={{ "width": "50%" }}>
                    <span className="form-input-label">
                    {i18n.t('points for trip')}
                    </span>
                    <input type="text" className="form-input" value={props.formDetails.pointsRequired} readOnly={true} disabled></input>
                </div>
                <div className="d-flex flex-column form-input-wrapper" style={{ "width": "50%" }}>
                    <span className="form-input-label">
                    {i18n.t('amount to pay')}
                    </span>
                    {props.pitInputs.totalSupplement.input}
                </div>
            </div>
            {
                !props.isAdmin ?
                    <div className="d-flex flex-row align-items-center checkbox-container form-input-wrapper" style={{ "marginBottom": "50px", "marginTop": "20px" }}>
                        <div className={props.approveForm ? "custom-checkbox custom-checkbox-active mr6" : "custom-checkbox mr6"} onClick={() => props.setApproveForm(!props.approveForm)} />
                        <div className="sub-header" style={{ "width": "fit-content" }}>{i18n.t('form data confirmation')}</div>
                    </div>
                    : <div style={{ "marginBottom": "50px" }}>
                    </div>
            }
        </div>
    )
}

export default PITForm;