import React, { useState } from 'react';
import '../custom.css';
import { GrClose } from 'react-icons/gr';
import i18n from 'i18next';

const RenderActionButtons = (props) => {
    return (
        <div className="d-flex flex-row mt-auto justify-content-end">
            <div >
                <button className="finish-action-btn button button-grey mr6" onClick={props.onNo}>
                {i18n.t('no')}
                 </button>
            </div>
            <div>
                <button className="finish-action-btn button button-red" onClick={props.onYes}>
                {i18n.t('yes')}
                 </button>
            </div>
        </div>
    );
}

const YesNoGenericPopup = (props) => {
    return (
        <div className='popup'>
            <div className='popup-open d-flex flex-column'>
                <div className="d-flex flex-row justify-content-between" style={{ marginBottom: "30px" }}>
                    <div className="popup-header">
                    {i18n.t('choice of reward')}
                    </div>
                    <div className="popup-close-icon-wrapper d-flex">
                        <GrClose role="button" onClick={props.closePopup} />
                    </div>
                </div>

                <div className="popup-content d-flex flex-column">
                {i18n.t('delete form warning')}
                </div>

                {RenderActionButtons(props)}
            </div>
        </div>
    );
}

export default YesNoGenericPopup;