import 'bootstrap/dist/css/bootstrap.css';
import 'react-datepicker/dist/react-datepicker.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as ApiAuthorizationConstants from './components/api-authorization/ApiAuthorizationConstants';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import WebFont from 'webfontloader';
import authService from './components/api-authorization/AuthorizeService';
import './utils/i18n';

WebFont.load({
    google: {
        families: ['Titillium Web:300,400,700', 'sans-serif', 'Open Sans:300,500,600,700']
    }
});

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <BrowserRouter basename={baseUrl}>
        <App />
    </BrowserRouter>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const { fetch: originalFetch } = window;

window.fetch = async (...args) => {
    let [resource, config] = args;
    var token = await authService.getAccessToken();
    if (config && config.headers && !config.headers['Authorization'] && token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    // check if token needs to be refreshed
    if (authService.tokenExpirationDate() && ((authService.tokenExpirationDate().getTime() - new Date().getTime()) < (3 /* minutes */ * 60 * 1000))) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        const response = await originalFetch('refreshToken', requestOptions);
        if (!response.ok && response.status === 401) {
            authService.updateState(null);
            window.location.replace(ApiAuthorizationConstants.ApplicationPaths.Login);
            return Promise.reject(response);
        }
        const data = await response.json();
        authService.updateState(data);
        token = await authService.getAccessToken();
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    let response = await originalFetch(resource, config);
    if (!response.ok && response.status === 401) {
        authService.updateState(null);
        window.location.replace(ApiAuthorizationConstants.ApplicationPaths.Login);
        return Promise.reject(response);
    }

    return response;
};