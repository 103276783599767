import React, { Component } from 'react';
import '../custom.css';
import { Audio } from 'react-loader-spinner'
import { RiImageAddLine } from 'react-icons/ri';
import authService from './api-authorization/AuthorizeService';
import i18n from 'i18next';

export class StaticBaner extends Component {

    constructor(props) {
        super(props);
        this.state = { baner: {}, loading: true, isEditMode: false, requestInProgress: false };
    }

    componentDidMount() {
        if (!this.isLoginUrl()) {
            this.populateData();
        }
    }

    enterEditMode = () => {
        this.setState({ ...this.state, isEditMode: true });
        this.orginalState = JSON.stringify(this.state);
    }

    getClickableLink = link => {
        return link.startsWith("http://") || link.startsWith("https://") ?
            link
            : `http://${link}`;
    };

    cancelEditMode = () => {
        this.setState({ ...JSON.parse(this.orginalState), isEditMode: false });
    }

    handleChangeImage = async e => {
        const file = e.target.files[0]
        const base64 = await this.convertBase64(file);
        this.setState({ ...this.state, baner: { ...this.state.baner, base64Content: base64 } })
    }

    handleImgUpload = event => {
        this.imgInput.click();
    };

    handleUrlChange = (event) => {
        this.setState({ ...this.state, baner: { ...this.state.baner, url: event.target.value } });
    }

    renderPanel(baner) {
        return (
            <div className={"baner-wrapper-no-padding " + (this.props.config.isAdmin ? "baner-wrapper-border" : "")}>
                <a href={this.getClickableLink(baner.url)} target='_blank'>
                    {baner.base64Content ? <img className="w-100 border-radius" src={baner.base64Content} /> : ''}
                </a>
                {
                    this.props.config.isAdmin ?
                        <div className="d-flex flex-row-reverse">
                            <div className="baner-edit-btn static-baner-edit-btn" onClick={this.enterEditMode}>
                            {i18n.t('change')}
                            </div>
                        </div> :
                        ""
                }
            </div>
        );
    }

    renderPanelInEdit() {
        return (
            <div className={"baner-wrapper-no-padding " + (this.props.config.isAdmin ? "baner-wrapper-border" : "")}>
                <div className="relative-wrapper">
                    <img className="w-100" src={this.state.baner.base64Content} />
                    <RiImageAddLine onClick={this.handleImgUpload} />
                    <input ref={input => this.imgInput = input} className="d-none" type="file" id="img" name="img" accept="image/*" onChange={this.handleChangeImage} />
                </div>

                <div className="p-20-px">
                    <div className="edit-input-label">
                    {i18n.t('click url')}
                    </div>
                    <div className="baner-header" >
                        <input className="baner-input baner-input-header" type="text" value={this.state.baner.url} onChange={this.handleUrlChange} />
                    </div>

                    <div className="baner-edit-btns d-flex flex-row justify-content-end">
                        <div >
                            <button className="finish-action-btn button button-transparent" onClick={this.cancelEditMode} >
                            {i18n.t('cancel')}
                        </button>
                        </div>
                        <div>
                            <button className="finish-action-btn button button-red" onClick={this.saveData} >
                            {i18n.t('save')}
                        </button>
                        </div>
                    </div>
                </div>


                {this.state.requestInProgress && <div className="overlay" />}
            </div>
        );
    }

    renderLoader() {
        return (
            <div className={"center-table-cell baner-wrapper-no-padding " + (this.props.config.isAdmin ? "baner-wrapper-border" : "")}>
                <Audio
                    height="50"
                    width="50"
                    color='grey'
                    ariaLabel='loading'
                    wrapperClass='d-block'
                />
            </div>
        );
    }

    render() {
        let contents = this.state.loading || !this.props.isConfigLoaded
            ? this.renderLoader()
            : this.state.isEditMode
                ? this.renderPanelInEdit()
                : this.renderPanel(this.state.baner);

        return (
            <div>
                {contents}
            </div>
        );
    }

    async populateData() {
        const token = await authService.getAccessToken();
        const response = await fetch('baner/static-baner', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ baner: data, loading: false });
    }

    saveData = async () => {
        const token = await authService.getAccessToken();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(this.state.baner)
        };

        this.setState({ ...this.state, requestInProgress: true });

        fetch('baner/static-baner', requestOptions)
            .then(response => response.json())
            .then(data => {
                this.setState({ ...this.state, baner: data, isEditMode: false, requestInProgress: false });
            });
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    isLoginUrl() {
        return window.location.pathname == '/authentication/login'
            || window.location.pathname == '/login-callback';
    }
}
