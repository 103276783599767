import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';


const resources = {
  pl: {
      translation: null
    }
};

const apiUrl = 'translations';

async function fetchTranslations() {
    try {
        const response = await fetch(apiUrl);

        if (response.ok) {
            const jsonData = await response.json();
            
            console.log('Dane JSON:', jsonData);
            resources.pl.translation = jsonData;
        } else {
            console.error('Error translations featching. Status:', response.status);
        }
    } catch (error) {
        console.error('Błąd:', error.message);
    }
}

fetchTranslations();


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'pl',
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      wait: true
    }
  });

export default i18n;