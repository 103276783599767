import React, { Component } from 'react';
import { EditableBaner } from './EditableBaner';
import { StaticBaner } from './StaticBaner';
import '../custom.css';

export class BannersWrapper extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <EditableBaner {...this.props} />
                <StaticBaner {...this.props} />
            </div>
        );
    }
}
