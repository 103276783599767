import React, { Component } from 'react';
import { FiInfo } from 'react-icons/fi';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import '../custom.css';
import { Audio } from 'react-loader-spinner'
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import UploadRewardListPopup from './UploadRewardListPopup';
import UploadBonusListPopup from './UploadBonusListPopup';
import AddMemberPopup from './AddMemberPopup';
import RealizeNotePopup from './RealizeNotePopup';
import UploadAgreementPopup from './UploadAgreementPopup';
import UploadAdditionalInfoPopup from './UploadAdditionalInfoPopup';
import authService from './api-authorization/AuthorizeService'
import ErrorMsg from './ErrorMsg'
import i18n from 'i18next';

export class AdminInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            summary: {},
            csvUploadResponse: undefined,
            bonusCsvUploadResponse: undefined,
            addMemberResponse: undefined,
            updateRealizeNoteResponse: undefined,
            updateAgreementResponse: undefined,
            updateAdditionalInfoResponse: undefined,
            loading: true,
            showRewardsPopup: false,
            showBonusPopup: false,
            showAddMemberPopup: false,
            showRealizeNotePopup: false,
            showAdditionalInfoPopup: false,
            showAgreementPopup: false,
            errorMsg: '',
            requestInProgress: false,
            additionalInfo: props.config.additionalInfo,
            additionalInfoFileName: props.config.additionalInfoFileName
        };
    }

    componentDidMount() {
        this.populateData();
    }

    componentDidUpdate(prevProps, prevState) {
        // ugly workaround - whole architectire needs to be moved to redux or something similar
        if (!prevProps.updateForced && this.props.updateForced) {
            this.populateData();
        }
    }

    toggleAgreementPopup = () => {
        this.setState({
            ...this.state,
            showAgreementPopup: !this.state.showAgreementPopup,
            updateAgreementResponse: undefined
        });
    }

    toggleRealizeNotePopup = () => {
        this.setState({
            ...this.state,
            showRealizeNotePopup: !this.state.showRealizeNotePopup,
            updateRealizeNoteResponse: undefined
        });
    }

    toggleAdditionalInfoPopup = () => {
        this.setState({
            ...this.state,
            showAdditionalInfoPopup: !this.state.showAdditionalInfoPopup,
            updateAdditionalInfoResponse: undefined
        });
    }

    toggleRewardPopup = () => {
        this.setState({
            ...this.state,
            showRewardsPopup: !this.state.showRewardsPopup,
            csvUploadResponse: undefined
        });
    }

    closeBonusPopup = () => {
        this.setState({
            ...this.state,
            showBonusPopup: false,
            bonusCsvUploadResponse: undefined
        });
    }

    toggleAddMemberPopup = () => {
        this.setState({
            ...this.state,
            showAddMemberPopup: !this.state.showAddMemberPopup,
            addMemberResponse: undefined,
        });
    }

    errorMsgClose = () => {
        this.setState({
            ...this.state,
            errorMsg: '',
            requestInProgress: false
        });
    }

    saveCsv = async (content) => {
        this.setState({ ...this.state, requestInProgress: true })
        const jsonData = { content: content };
        const token = await authService.getAccessToken();

        if (content) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData)
            };
            fetch('reward', requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.message) {
                        this.setState({ ...this.state, errorMsg: data.message, requestInProgress: false })
                    } else {
                        this.setState({ ...this.state, csvUploadResponse: data, requestInProgress: false });
                    }
                });
        }
    }

    getBonusCsv = async () => {
        const token = await authService.getAccessToken();
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        fetch('promo', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.message) {
                    this.setState({ ...this.state, errorMsg: data.message })
                } else {
                    this.setState({
                        ...this.state,
                        currentPromotions: data.content,
                        showBonusPopup: true,
                        bonusCsvUploadResponse: undefined
                    });
                }
            });
    }

    saveBonusCsv = async (content) => {
        this.setState({ ...this.state, requestInProgress: true })
        const jsonData = { content: content };
        const token = await authService.getAccessToken();

        if (content) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData)
            };
            fetch('promo', requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.message) {
                        this.setState({ ...this.state, errorMsg: data.message, requestInProgress: false })
                    } else {
                        this.setState({ ...this.state, bonusCsvUploadResponse: data, requestInProgress: false });
                    }
                });
        }
    }

    addMember = async (email, joiningDate) => {
        this.setState({ ...this.state, requestInProgress: true })
        const jsonData = { email: email, joiningDate: joiningDate };
        const token = await authService.getAccessToken();

        if (email) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData)
            };
            fetch('admin', requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.message) {
                        this.setState({ ...this.state, errorMsg: data.message, requestInProgress: false })
                    } else {
                        this.setState({ ...this.state, addMemberResponse: data, requestInProgress: false });
                    }
                });
        }
    }

    updateRealizeNote = async (realizeNote) => {
        this.setState({ ...this.state, requestInProgress: true })
        const jsonData = { content: realizeNote };
        const token = await authService.getAccessToken();

        if (realizeNote) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData)
            };
            fetch('realizeNote', requestOptions)
                .then(response => response.json())
                .then(data => {
                    this.setState({ ...this.state, updateRealizeNoteResponse: data, requestInProgress: false });
                    this.props.config.realizeNote = data.content;
                });
        }
    }

    updateAgreement = async (agreement) => {
        this.setState({ ...this.state, requestInProgress: true })
        agreement = agreement.replace('data:application/pdf;base64,', '')
        const jsonData = { agreement: agreement };
        const token = await authService.getAccessToken();

        if (agreement) {

            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData)
            };
            fetch('agreement', requestOptions)
                .then(response => response.json())
                .then(data => {
                    this.setState({ ...this.state, updateAgreementResponse: data, requestInProgress: false });
                    this.props.config.agreementUpdateDate = data.updateDate
                });
        }
    }

    updateAdditionalInfo = async (content, fileName) => {
        this.setState({ ...this.state, requestInProgress: true })
        content = content.replace('data:application/pdf;base64,', '')
        const jsonData = { content: content, fileName: fileName };
        const token = await authService.getAccessToken();

        if (content) {

            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData)
            };
            fetch('additionalInfo', requestOptions)
                .then(response => response.json())
                .then(data => {
                    this.setState({ ...this.state,
                        updateAdditionalInfoResponse: data,
                        requestInProgress: false,
                        additionalInfo: content,
                        additionalInfoFileName: fileName,
                        showAdditionalInfoPopup: false  });
                });
        }
    }

    removeAdditionalInfo = async () => {
        this.setState({ ...this.state, requestInProgress: true })
        const token = await authService.getAccessToken();

        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        fetch('additionalInfo', requestOptions)
            .then(response => response.json())
            .then(data => {
                this.setState({ ...this.state,
                    updateAdditionalInfoResponse: data,
                    requestInProgress: false,
                    additionalInfo: undefined,
                    additionalInfoFileName: undefined,
                    showAdditionalInfoPopup: false });
            });
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    renderPanel(summary) {
        return (
            <div>
                <div className="d-flex justify-content-between">
                    <div className="header" >
                        {i18n.t('welcome')} {summary.loggedUserName}
                    </div>
                    <div>
                        <FiInfo />
                    </div>
                </div>

                <div className="sub-header">
                    {i18n.t('bonus program summary')}
                </div>
                <div className="summary-regular">
                    {i18n.t('member conunt')} {summary.totalMembers.toLocaleString('en').replace(/,/g, " ")}
                </div>
                <div className="summary-regular">
                    {i18n.t('points amout')} {summary.totalPoints.toLocaleString('en').replace(/,/g, " ")}
                </div>
                <div className="summary-regular">
                    {i18n.t('potential points amount')} {summary.totalPotentialPoints.toLocaleString('en').replace(/,/g, " ")}
                </div>
                <div className="add-new-member" onClick={this.toggleAddMemberPopup}>
                    <div className="mr6 d-flex">
                        <AiOutlinePlusCircle />
                    </div>
                    <div >
                        {i18n.t('add new member')}
                    </div>
                </div>
                <div className="mb10">
                    <NavLink tag={Link} className="button button-red" to="/form-list"
                        state={{ isAdmin: this.props.config.isAdmin }}

                    >{i18n.t('forms')} ({summary.totalForms})</NavLink>
                </div>
                <div className="mb50">
                    <NavLink tag={Link} className="button button-grey" to="/statistics">{i18n.t('statistics')}</NavLink>
                </div>
                <div className="static-link mb14" onClick={this.toggleRewardPopup}>{i18n.t('update rewards list')}</div>
                <div className="static-link mb14" onClick={this.getBonusCsv}>{i18n.t('update special offer')}</div>
                <div className="d-flex flex-column align-items-begin">
                    <div className="static-link mr6" onClick={this.toggleAgreementPopup}>
                    {i18n.t('regulations')}
                    </div>
                    <span className="target-points">{i18n.t('update_')} {new Date(this.props.config.agreementUpdateDate).toLocaleDateString()}</span>
                </div>
                <div className="static-link mb14 mt-14" onClick={this.toggleRealizeNotePopup}>{i18n.t('how to realize reward')}</div>
                <div className="static-link mb14 mt-14" onClick={this.toggleAdditionalInfoPopup}>{i18n.t('additional info')}</div>

                {this.state.showRewardsPopup ?
                    <UploadRewardListPopup
                        csvUploadResponse={this.state.csvUploadResponse}
                        closePopup={this.toggleRewardPopup}
                        saveCsv={this.saveCsv}
                        requestInProgress={this.state.requestInProgress}
                    />
                    : null
                }

                {this.state.showBonusPopup ?
                    <UploadBonusListPopup
                        bonusCsvUploadResponse={this.state.bonusCsvUploadResponse}
                        currentPromotions={this.state.currentPromotions}
                        closePopup={this.closeBonusPopup}
                        saveCsv={this.saveBonusCsv}
                        requestInProgress={this.state.requestInProgress}
                    />
                    : null
                }

                {this.state.showAddMemberPopup ?
                    <AddMemberPopup
                        addMember={this.addMember}
                        closePopup={this.toggleAddMemberPopup}
                        addMemberResponse={this.state.addMemberResponse}
                        requestInProgress={this.state.requestInProgress}
                    />
                    : null
                }

                {this.state.showRealizeNotePopup ?
                    <RealizeNotePopup
                        updateRealizeNote={this.updateRealizeNote}
                        closePopup={this.toggleRealizeNotePopup}
                        updateRealizeNoteResponse={this.state.updateRealizeNoteResponse}
                        realizeNote={this.props.config.realizeNote}
                        requestInProgress={this.state.requestInProgress}
                    />
                    : null
                }

                {this.state.showAdditionalInfoPopup ?
                    <UploadAdditionalInfoPopup
                        showRemoveButton={!!this.state.additionalInfo}
                        fileName={this.state.additionalInfoFileName}
                        updateAdditionalInfo={this.updateAdditionalInfo}
                        removeAdditionalInfo={this.removeAdditionalInfo}
                        closePopup={this.toggleAdditionalInfoPopup}
                        updateAdditionalInfoResponse={this.state.updateAgreementResponse}
                        requestInProgress={this.state.requestInProgress}
                    />
                    : null
                }                

                {this.state.showAgreementPopup ?
                    <UploadAgreementPopup
                        updateAgreement={this.updateAgreement}
                        closePopup={this.toggleAgreementPopup}
                        updateAgreementResponse={this.state.updateAdditionalInfoResponse}
                        requestInProgress={this.state.requestInProgress}
                    />
                    : null
                }

                {this.state.errorMsg ?
                    <ErrorMsg
                        errorMsg={this.state.errorMsg}
                        close={this.errorMsgClose} />
                    : null
                }
            </div>
        );
    }

    renderLoader() {
        return (
            <div className="center-table-cell">
                <Audio
                    height="50"
                    width="50"
                    color='grey'
                    ariaLabel='loading'
                    wrapperClass='d-block'
                />
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? this.renderLoader()
            : this.renderPanel(this.state.summary);

        return (
            <div>
                {contents}
            </div>
        );
    }

    async populateData() {
        const token = await authService.getAccessToken();
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        const response = await fetch('summary', requestOptions);
        const data = await response.json();
        this.setState({ summary: data, loading: false });
    }
}
