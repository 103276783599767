import React, { useState, useEffect } from 'react';
import '../../custom.css';
import { GrClose } from 'react-icons/gr';
import { useParams } from 'react-router-dom';
import authService from '../api-authorization/AuthorizeService'
import { Audio } from 'react-loader-spinner'
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { MdOutlineRemoveCircleOutline } from 'react-icons/md';
import { FaRegEdit } from 'react-icons/fa';
import AddNotePopup from './user-detail-popups/AddNotePopup'
import PointsUpdatePopup from './user-detail-popups/PointsUpdatePopup'
import { TiAttachment } from 'react-icons/ti';
import ErrorMsg from '../ErrorMsg'
import RemoveUserPopup from './user-detail-popups/RemoveUserPopup';
import SuspendUserPopup from './user-detail-popups/SuspendUserPopup';
import UnsuspendUserPopup from './user-detail-popups/UnsuspendUserPopup';
import i18n from 'i18next';

const renderLoader = () => {
    return (
        <div className="center-table-cell baner-wrapper-no-padding">
            <Audio
                height="50"
                width="50"
                color='grey'
                ariaLabel='loading'
                wrapperClass='d-block'
            />
        </div>
    );
}

const dateOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };

const renderDetails = (userDetails, callbacks, addNoteOpen, removePopupOpen, suspendPopupOpen, unsuspendPopupOpen, pointsPopup) => {
    return (
        <div className="form-list-wrapper">
            <div className="d-flex flex-row justify-content-between align-content-center">
                <div className="table-tab-header-static text-begin">
                    {userDetails.number} {userDetails.firstName} {userDetails.lastName} 
                    <span className="user-details-address" style={{ "margin-left": "5px" }}>{userDetails.email}</span>
                </div>
                <NavLink tag={Link} to="/"><GrClose role="button" /></NavLink>
            </div>
            <div className="d-flex flex-column">
                <div className="user-details-date" style={{ "marginTop": "-5px" }}>
                {i18n.t('member added date')} {userDetails.addDate ? new Date(userDetails.addDate).toLocaleDateString() : ""}
                </div>
                {userDetails.suspended ?
                <div className="user-details-date" style={{ "marginTop": "-5px" }}>
                    {i18n.t('suspend date')} {userDetails.suspendedDate ? new Date(userDetails.suspendedDate).toLocaleTimeString(undefined, dateOptions) : ""}
                    {userDetails.suspendedEndDate ? ` - ${new Date(userDetails.suspendedEndDate).toLocaleTimeString(undefined, dateOptions)}` : ""}
                </div>
                : null
                }
                <div className="user-details-address" style={{ "marginTop": "20px" }}>
                    {userDetails.address}
                </div>
                <div className="baner-header" style={{ "marginTop": "40px" }}>
                {i18n.t('points')}
                </div>
                <div className="user-details-earned-points" style={{ "marginTop": "12px" }}>
                {i18n.t('number of points')} {userDetails.earnedPoints}
                </div>
                <div className="user-details-potential-points">
                {i18n.t('number of potential points')} {userDetails.potentialPoints}
                </div>
                <div className="user-details-action-link" style={{ "marginTop": "10px" }} onClick={callbacks.onEdit}>
                    <div className="mr6 d-flex">
                        <FaRegEdit />
                    </div>
                    <div >
                    {i18n.t('edit')}
                    </div>
                </div>
                {
                    userDetails.notes?.length
                        ? <div className="baner-header" style={{ "marginTop": "40px", "marginBottom": "20px" }}>
                            {i18n.t('notes')}
                           </div>
                        : null
                }
                <div className="d-flex flex-column">
                    {userDetails.notes.map((note, index) => (
                        <div className="d-flex flex-column" key={index}>
                            <div className="note-content">
                                {note.content}
                            </div>
                            {
                                note.attachmentContent && note.attachmentName
                                    ? <div className="d-flex flex-row user-details-action-link note-attachment" style={{ "marginTop": "8px", "marginBottom": "5px" }}>
                                        <div className="mr6 d-flex">
                                            <TiAttachment />
                                        </div>
                                        <div >
                                            <a href={note.attachmentContent} download={note.attachmentName}>{note.attachmentName}</a>
                                        </div>
                                    </div>
                                    : null
                            }

                            <div className="user-details-date" style={{ "marginBottom": "30px" }}>
                            {i18n.t('added date')} {note.date ? new Date(note.date).toLocaleDateString() : ""} {i18n.t('by')} {note.author}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="user-details-action-link" style={{ "marginTop": "40px" }} onClick={callbacks.onAddNote}>
                    <div className="mr6 d-flex">
                        <AiOutlinePlusCircle />
                    </div>
                    <div >
                    {i18n.t('add note')}
                    </div>
                </div>

                <div className="user-details-action-link" style={{ "marginTop": "10px" }} onClick={callbacks.onSuspend}>
                    <div className="mr6 d-flex">
                        {userDetails.suspended ? <AiOutlinePlusCircle /> : <MdOutlineRemoveCircleOutline />}
                    </div>
                    <div >
                        {userDetails.suspended ? i18n.t('unsuspend') : i18n.t('suspend')}
                    </div>
                </div>

                <div className="user-details-action-link" style={{ "marginTop": "10px" }} onClick={callbacks.onRemove}>
                    <div className="mr6 d-flex">
                        <MdOutlineRemoveCircleOutline />
                    </div>
                    <div >
                        {userDetails.removed ? i18n.t('removed from program') : i18n.t('remove from program')}
                    </div>
                </div>
            </div>

            {addNoteOpen
                ? <AddNotePopup
                    closePopup={callbacks.closeAddNotePopup}
                    noteContentChanged={callbacks.onNewNoteContentChange}
                    addNote={callbacks.addNote}
                    name={userDetails.number + " " + userDetails.firstName + " " + userDetails.lastName}
                />
                : null}

            {removePopupOpen
                ? <RemoveUserPopup
                    closePopup={callbacks.closeRemovePopup}
                    remove={callbacks.remove}
                />
                : null}

            {suspendPopupOpen
                ? <SuspendUserPopup
                    closePopup={callbacks.closeSuspendPopup}
                    suspend={callbacks.suspend}
                />
                : null}

            {unsuspendPopupOpen
                ? <UnsuspendUserPopup
                    closePopup={callbacks.closeSuspendPopup}
                    userDetails={userDetails}
                    unsuspend={callbacks.unsuspend}
                />
                : null}

            {pointsPopup
                ? <PointsUpdatePopup
                    closePopup={callbacks.closePointsPopup}
                    updatePoints={callbacks.updatePoints}
                    name={userDetails.number + " " + userDetails.firstName + " " + userDetails.lastName}
                />
                : null}
        </div>
    );
}

const UserDetails = (props) => {
    const [userDetails, setUserDetails] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [notePopup, setNotePopup] = useState(false);
    const [removePopup, setRemovePopup] = useState(false);
    const [suspendPopup, setSuspendPopup] = useState(false);
    const [unsuspendPopup, setUnsuspendPopup] = useState(false);
    const [pointsPopup, setPointsPopup] = useState(false);
    const [newNote, setNewNote] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const params = useParams();

    const downloadUserDetails = async () => {
        setLoading(true)
        const encodedId = encodeURIComponent(params.userId);

        const token = await authService.getAccessToken();
        const response = await fetch(`admin/users/${encodedId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        setUserDetails({ ...data, removed: false });
        setLoading(false)
    }

    const onEdit = () => {
        setPointsPopup(true);
    }

    const onAddNote = () => {
        setNotePopup(true);
    }

    const closeAddNotePopup = () => {
        setNotePopup(false);
    }

    const onRemove = () => {
        if (userDetails.removed) {
            return;
        }

        setRemovePopup(true);
    }

    const closeRemovePopup = () => {
        setRemovePopup(false);
    }

    const onSuspend = () => {
        if (userDetails.suspended) {
            setUnsuspendPopup(true);
         } else {
            setSuspendPopup(true)
         }
    }

    const closeSuspendPopup = () => {
        setSuspendPopup(false);
        setUnsuspendPopup(false);
    }

    const onAddNoteSubmit = async (noteData) => {
        const note = { date: noteData.currentDate, content: newNote, author: userDetails.adminName, attachmentName: noteData.fileName, attachmentContent: noteData.file };

        const encodedId = encodeURIComponent(params.userId);
        const token = await authService.getAccessToken();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(note)
        };

        fetch(`admin/users/${encodedId}/note`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setUserDetails({ ...userDetails, notes: [...userDetails.notes, note] });
                setNotePopup(false);
            });
    }

    const remove = async () => {
        if (userDetails.removed) {
            return;
        }

        const encodedId = encodeURIComponent(params.userId);
        const token = await authService.getAccessToken();
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        };

        fetch(`admin/users/${encodedId}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setUserDetails({ ...userDetails, removed: true });
            });
            
        setRemovePopup(false);
    }

    const suspend = async () => {
        if (userDetails.suspended) {
            return;
        }

        const encodedId = encodeURIComponent(params.userId);
        const token = await authService.getAccessToken();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        };

        fetch(`admin/users/${encodedId}/suspend`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.message) {
                    setErrorMsg(data.message);
                } else {
                    downloadUserDetails(setUserDetails);
                    setSuspendPopup(false);
                }});
    }

    const unsuspend = async (payload) => {
        if (!userDetails.suspended) {
            return;
        }

        const body = {
            endDate: payload
        }

        const encodedId = encodeURIComponent(params.userId);
        const token = await authService.getAccessToken();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body)
        };

        fetch(`admin/users/${encodedId}/unsuspend`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.message) {
                    setErrorMsg(data.message);
                } else {
                    downloadUserDetails(setUserDetails);
                    setUnsuspendPopup(false);
                }});
    }

    const onPointsUpdate = async (payload) => {
        const points = {
            pointsUpdateDate: payload.currentDate,
            newEarnedPoints: payload.earnedPoints,
            comment: payload.comment,
            expirationDate: payload.expirationDate
        };

        const encodedId = encodeURIComponent(params.userId);
        const token = await authService.getAccessToken();
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(points)
        };

        fetch(`admin/users/${encodedId}/points`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.message) {
                    setErrorMsg(data.message);
                } else {
                    downloadUserDetails(setUserDetails);
                    setPointsPopup(false);
                }
            });
    }

    const callbacks = {
        onEdit: onEdit,
        onAddNote: onAddNote,
        onRemove: onRemove,
        onSuspend: onSuspend,
        closeAddNotePopup: closeAddNotePopup,
        closeRemovePopup: closeRemovePopup,
        closeSuspendPopup: closeSuspendPopup,
        onNewNoteContentChange: setNewNote,
        addNote: onAddNoteSubmit,
        remove: remove,
        suspend: suspend,
        unsuspend: unsuspend,
        closePointsPopup: () => setPointsPopup(false),
        updatePoints: onPointsUpdate
    }

    useEffect(() => {
        if (!userDetails) {
            downloadUserDetails(setUserDetails);
        }
    });

    let contents = loading
        ? renderLoader()
        : renderDetails(userDetails, callbacks, notePopup, removePopup, suspendPopup, unsuspendPopup, pointsPopup);

    return (
        <div>
            {contents}

            {errorMsg ?
                <ErrorMsg
                    errorMsg={errorMsg}
                    close={() => setErrorMsg('')} />
                : null
            }
        </div>
    );


}

export default UserDetails;