import React, { useState } from 'react';
import '../custom.css';
import { FiInfo } from 'react-icons/fi';
import { AiOutlineSearch } from 'react-icons/ai';
import { FcOk } from 'react-icons/fc';
import i18n from 'i18next';

const RewardForm = (props) => {
    const formatMoney = (input) => {
        if (!input) {
            return "-"
        }
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: i18n.t('currency') }).format(input).replace(".", " ");
    }

    const formatPoints = (input) => {
        return input ? input : "-";
    }

    const formatBool = (input) => {
      return input ? i18n.t("yes") : i18n.t("no");
    }

    return (
        <div className="d-flex flex-column">
            <div className="form-header">
            {i18n.t('seller data')}
            </div>
            <span className="form-input-label form-input-wrapper">
            {i18n.t('seller')}
            </span>
            <div className="d-flex flex-row align-items-center text-center">
                <div className="form-input-wrapper" style={{ "width": "55px" }}>
                    <input type="text" className="form-input" value={props.formDetails.agentId} readOnly={true} disabled></input>
                </div>
                <div className="form-input-label form-info-label">
                    {`${props.formDetails.agentFirstName ?? ""} ${props.formDetails.agentLastName ?? ""}`}
                </div>
            </div>
            <div className="d-flex flex-row" style={{ "marginTop": "15px" }}>
                <div className="d-flex flex-column form-input-wrapper" style={{ "width": "50%" }}>
                    <span className="form-input-label">
                    {i18n.t('email')}
                    </span>
                    {props.rewardInputs.agentEmail.input}
                </div>
                <div className="d-flex flex-column form-input-wrapper" style={{ "width": "50%" }}>
                    <span className="form-input-label">
                    {i18n.t('phone')}
                    </span>
                    {props.rewardInputs.agentPhone.input}
                </div>
            </div>
            <div className="form-header" style={{ "marginTop": "50px" }}>
            {i18n.t('selected reward')}
            </div>
            <div className="d-flex flex-row">
                <div className="d-flex flex-column form-input-wrapper" style={{ "width": "125px" }}>
                    <span className="form-input-label">
                    {i18n.t('reward price')}
                    </span>
                    <input type="text" className="form-input" value={props.formDetails.rewardPoints} readOnly={true} disabled></input>
                </div>
                <div className="d-flex flex-column form-input-wrapper" style={{ "width": "80px" }}>
                    <span className="form-input-label">
                    {i18n.t('code')}
                    </span>
                    <input type="text" className="form-input" value={props.formDetails.rewardCode} readOnly={true} disabled></input>
                </div>
                <div className="d-flex flex-column form-input-wrapper" style={{ "flexGrow": "1" }}>
                    <span className="form-input-label">
                    {i18n.t('destination')}
                    </span>
                    <input type="text" className="form-input" value={props.formDetails.rewardDirection} readOnly={true} disabled></input>
                </div>
            </div>
            <div className="d-flex flex-row" style={{ "marginTop": "15px" }}>
                <div className="d-flex flex-column form-input-wrapper" style={{ "width": "100%" }}>
                    <span className="form-input-label">
                    {i18n.t('hotel trip name')}
                    </span>
                    <input type="text" className="form-input" value={props.formDetails.rewardName} readOnly={true} disabled></input>
                </div>
            </div>
            <div className="d-flex flex-row text-center form-info form-input-wrapper" style={{ "marginTop": "28px" }}>
                <FiInfo />
                <div className="form-input-label form-info-label">
                {i18n.t('reward change confirmation')}
                </div>
            </div>
            <div className="form-header" style={{ "marginTop": "50px" }}>
            {i18n.t('booking')}
            </div>
            <div className="d-flex flex-row">
                <div className="d-flex flex-column form-input-wrapper">
                    <span className="form-input-label">
                    {i18n.t('booking number')}
                    </span>
                    <div className="d-flex flex-row align-items-center">
                        <div className="reservation-number-container mr6">
                            {props.rewardInputs.reservationNumber.input}
                            <AiOutlineSearch onClick={() => props.isAdmin || props.downloadReservations(props.rewardInputs.reservationNumber.value)} />
                        </div>
                        {
                            props.formDetails.reservationDownloaded && props.rewardInputs.reservationNumber.valid
                                ? <div>
                                    <FcOk />
                                </div>
                                : null
                        }
                    </div>
                </div>
            </div>
            {
                props.formDetails.reservationDates
                    ? <div className="d-flex flex-row" style={{ "marginTop": "15px" }}>
                        <div className="d-flex flex-column form-input-wrapper">
                            <span className="form-input-label">
                            {i18n.t('derparture arrival date')}
                            </span>
                            <input type="text" className="form-input" value={props.formDetails.reservationDates} readOnly={true} disabled></input>
                        </div>
                    </div>
                    : null
            }

            {
                props.formDetails.tripMembers
                    ? <span className="form-input-label form-input-wrapper" style={{ "marginTop": "15px" }}>
                        {i18n.t('participants')}
                        </span>
                    : null
            }

            {props.formDetails.tripMembers?.map((member, index) => (
                <div className="d-flex flex-row form-input-wrapper" key={index} style={{ "marginBottom": "15px" }}>
                    <span className="form-input-label form-info-label">{index + 1}.</span>
                    <input type="text" className="form-input" value={member} readOnly={true} disabled></input>
                </div>
            ))}
            <div className="form-header" style={{ "marginTop": "35px" }}>
            {i18n.t('price summary')}
            </div>
            <div className="d-flex flex-row justify-content-between form-input-wrapper form-summary">
                <span>{i18n.t('missing points fee')} </span>
                <span>{formatBool(props.formDetails.supplementRequired)}</span>
            </div>
            <div className="d-flex flex-row justify-content-between form-input-wrapper form-summary">
                <span>{i18n.t('missing points')}</span>
                <span>{formatPoints(props.formDetails.missingPoints)}</span>
            </div>
            <div className="d-flex flex-row justify-content-between form-input-wrapper form-summary">
                <span>{i18n.t('seller fee')}</span>
                <span>{formatMoney(props.formDetails.agentsSupplement)}</span>
            </div>
            <div className="form-input-wrapper form-summary" style={{ "marginTop": "20px", "marginBottom": "20px" }}>
            {i18n.t('foreign points info')}
            </div>
            <div className="form-summary" style={{ "marginLeft": "8px" }}>
                <div className="d-flex flex-row" style={{ marginBottom: "15px" }}>
                    <div className="base-form-input-label wp-75">
                    {i18n.t('foreign points seller number')}
                        </div>
                    <div className="wp-25">
                        {props.rewardInputs.externalPointsAgentNumber.input}
                    </div>
                </div>
                <div className="d-flex flex-row" style={{ marginBottom: "15px" }}>
                    <div className="base-form-input-label wp-75">
                    {i18n.t('foreign points amount')}
                        </div>
                    <div className="wp-25">
                        {props.rewardInputs.externalPointsAmount.input}
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row text-center form-info form-input-wrapper form-summary" style={{ "marginTop": "28px", "marginBottom": "70px" }}>
                <FiInfo />
                <div className="form-input-label form-info-label">
                {i18n.t('verification info time')}
                </div>
            </div>
        </div>
    )
}

export default RewardForm;