import React, { Component } from 'react';
import '../custom.css';
import { Audio } from 'react-loader-spinner'
import classNames from 'classnames';
import { RiImageAddLine } from 'react-icons/ri';
import { DefaultEditor } from 'react-simple-wysiwyg';
import { CustomSelect } from './CustomSelect';
import authService from './api-authorization/AuthorizeService'
import i18n from 'i18next';

export class EditableBaner extends Component {

    constructor(props) {
        super(props);
        this.state = { baner: {}, loading: true, isEditMode: false, requestInProgress: false, descriptionVisible: false };
    }

    componentDidMount() {
        if (!this.isLoginUrl()) {
            this.populateData();
        }
    }

    enterEditMode = () => {
        this.setState({ ...this.state, isEditMode: true });
        this.orginalState = JSON.stringify(this.state);
    }

    toggleDescriptionCollapse = () => {
        this.setState({ ...this.state, descriptionVisible: !this.state.descriptionVisible });
    }

    cancelEditMode = () => {
        this.setState({ ...JSON.parse(this.orginalState), isEditMode: false });
    }

    saveData = async () => {
        const token = await authService.getAccessToken();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(this.state.baner)
        };

        this.setState({ ...this.state, requestInProgress: true });
        await fetch('baner', requestOptions)
        this.setState({ ...this.state, isEditMode: false, requestInProgress: false });
    }

    handleHeaderChange = (event) => {
        this.setState({ ...this.state, baner: { ...this.state.baner, header: event.target.value } });
    }

    handleSubheaderChange = (event) => {
        this.setState({ ...this.state, baner: { ...this.state.baner, subheader: event.target.value } });
    }

    handleChangeImage = async e => {
        const file = e.target.files[0]
        const base64 = await this.convertBase64(file);
        this.setState({ ...this.state, baner: { ...this.state.baner, base64Content: base64 } })
    }

    handleSelectedOption = (selectedOption) => {
        let banerTypeText = selectedOption == 0 ? '' : selectedOption == 1 ? i18n.t('new') : i18n.t('special offer');
        this.setState({ ...this.state, baner: { ...this.state.baner, type: selectedOption, typeText: banerTypeText } });
    }

    handleImgUpload = event => {
        this.imgInput.click();
    };

    handleDescriptionChange = e => {
        this.setState({ ...this.state, baner: { ...this.state.baner, description: e.target.value } });
    }

    renderPanel(baner) {
        let banerClasses = classNames(
            {
                'baner-new': baner.type === 1,
                'baner-promo': baner.type === 2
            }
        );

        let toggleCollapseButtonText = this.state.descriptionVisible ? i18n.t("less") : i18n.t("more");

        return (
            <div className={"baner-wrapper " + (this.props.config.isAdmin ? "baner-wrapper-border" : "")}>
                <div className="d-flex justify-content-between">
                    <div className="baner-header" >
                        {baner.header}
                    </div>
                    <div className={banerClasses}>
                        {baner.typeText}
                    </div>
                </div>
                <div className="baner-sub-header">
                    {baner.subheader}
                </div>
                <img className="w-100 baner-image" src={this.state.baner.base64Content} />
                {
                    this.state.descriptionVisible ? <div className="baner-regular">
                        <div dangerouslySetInnerHTML={{ __html: baner.description }}></div>
                    </div> : null
                }
                {
                    !this.props.config.isAdmin ? <div className="static-link mr6 text-center" onClick={this.toggleDescriptionCollapse}> {toggleCollapseButtonText} </div> : null
                }
                {
                    this.props.config.isAdmin ?
                        <div className="d-flex flex-row-reverse">
                            <div className="baner-edit-btn" onClick={this.enterEditMode}>
                            {i18n.t('edit')}
                            </div>
                        </div>
                        :
                        <div />
                }
            </div>
        );
    }

    renderPanelInEdit() {
        return (
            <div className="baner-wrapper-in-edit">
                <div className="baner-header" >
                    <input className="baner-input baner-input-header" type="text" value={this.state.baner.header} onChange={this.handleHeaderChange} />
                </div>

                <div className="baner-sub-header" >
                    <input className="baner-input baner-input-subheader" type="text" value={this.state.baner.subheader} onChange={this.handleSubheaderChange} />
                </div>
                <div className="relative-wrapper">
                    <img className="w-100 baner-image" src={this.state.baner.base64Content} />
                    <RiImageAddLine onClick={this.handleImgUpload} />
                    <input ref={input => this.imgInput = input} className="d-none" type="file" id="img" name="img" accept="image/*" onChange={this.handleChangeImage} />
                </div>
                <div className="relative-editor-wrapper">
                    <DefaultEditor value={this.state.baner.description} onChange={this.handleDescriptionChange} />

                    <CustomSelect selectedOption={this.state.baner.type} setSelectedOption={this.handleSelectedOption} />
                </div>

                <div className="baner-edit-btns d-flex flex-row justify-content-end">
                    <div >
                        <button className="finish-action-btn button button-transparent" onClick={this.cancelEditMode} >
                        {i18n.t('cancel')}
                        </button>
                    </div>
                    <div>
                        <button className="finish-action-btn button button-red" onClick={this.saveData} >
                        {i18n.t('save')}
                        </button>
                    </div>
                </div>

                {this.state.requestInProgress && <div className="overlay" />}
            </div>
        );
    }

    renderLoader() {
        return (
            <div className={"center-table-cell baner-wrapper " + (this.props.config.isAdmin ? "baner-wrapper-border" : "")}>
                <Audio
                    height="50"
                    width="50"
                    color='grey'
                    ariaLabel='loading'
                    wrapperClass='d-block'
                />
            </div>
        );
    }

    render() {
        let contents = this.state.loading || !this.props.isConfigLoaded
            ? this.renderLoader()
            : this.state.isEditMode
                ? this.renderPanelInEdit()
                : this.renderPanel(this.state.baner);

        return (
            <div>
                {contents}
            </div>
        );
    }

    async populateData() {
        const token = await authService.getAccessToken();
        const response = await fetch('baner', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ baner: data, loading: false });
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    isLoginUrl() {
        return window.location.pathname == '/authentication/login'
            || window.location.pathname == '/login-callback';
    }
}
