import React, { Component } from 'react';
import { UserList } from "./UserList";
import { ReservationList } from "./ReservationList";
import '../custom.css';

export class CenterWrapper extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                {this.props.config.isAdmin ?
                    <UserList {...this.props} /> :
                    <ReservationList {...this.props} />}
            </div>
        );
    }
}
