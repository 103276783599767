import { useState } from 'react';
import { MdOutlineKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import i18n from 'i18next';

function YearCarousel(props) {
    const currentYear = new Date().getFullYear();
    const [activeYearLabel, setActiveYearLabel] = useState(currentYear);

    const prev = () => {
        setActiveYearLabel(activeYearLabel - 1)
        props.yearChanged(activeYearLabel - 1);
    }

    const next = () => {
        setActiveYearLabel(activeYearLabel + 1)
        props.yearChanged(activeYearLabel + 1);
    }

    return (
        <div>
            {/* <ReactSimplyCarousel
                activeSlideIndex={activeSlideIndex}
                onRequestChange={setActiveSlideIndex}
                itemsToShow={3}
                itemsToScroll={1}
                forwardBtnProps={{
                    style: {
                        alignSelf: 'center',
                        textAlign: 'center',

                        border: 'none',
                        borderRadius: '50%',

                        color: 'black',
                        background: 'white',
                        cursor: 'pointer',

                        height: 30,
                        width: 30,
                    },
                    children: <MdKeyboardArrowRight />,
                }}
                backwardBtnProps={{
                    style: {
                        alignSelf: 'center',
                        textAlign: 'center',

                        border: 'none',
                        borderRadius: '50%',

                        color: 'black',
                        background: 'white',
                        cursor: 'pointer',

                        height: 30,
                        width: 30,
                    },
                    children: <MdOutlineKeyboardArrowLeft />,
                }}
                speed={200}
                easing="ease-in-out"
                centerMode={true}
            >
                <div style={carItemStyle}>
                    Rok 2020
                </div>
                <div style={{ width: 74, height: 25, background: 'red', fontSize: '13px', alignSelf: 'center' }}>
                    Rok 2021
                </div>
                <div style={{ width: 74, height: 25, background: 'blue', fontSize: '13px', alignSelf: 'center' }}>
                    Rok 2022
                </div>
                <div style={{ width: 74, height: 25, background: 'green', fontSize: '13px', alignSelf: 'center' }}>
                    Rok 2023
                </div>
                <div style={{ width: 74, height: 25, background: 'black', fontSize: '13px', alignSelf: 'center' }}>
                    Rok 2024
                </div>
            </ReactSimplyCarousel> */}
            <div className="d-flex flex-row year-carousel-container">
                <MdOutlineKeyboardArrowLeft onClick={prev} />
                <div className="year-carousel-item" onClick={prev} style={{ "cursor": "pointer" }}>
                {i18n.t('year')} {activeYearLabel - 1}
                </div>
                <div className="year-carousel-item year-carousel-item-active">
                {i18n.t('year')} {activeYearLabel}
                </div>
                <div className="year-carousel-item" onClick={next} style={{ "cursor": "pointer" }}>
                {i18n.t('year')} {activeYearLabel + 1}
                </div>
                <MdKeyboardArrowRight onClick={next} />
            </div>
        </div>
    );
}

export default YearCarousel;