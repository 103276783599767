import React, { Component } from 'react';
import '../custom.css';
import { Audio } from 'react-loader-spinner'
import { GrClose } from 'react-icons/gr';
import { AiOutlineSearch, AiOutlineDownload, AiOutlineEye } from 'react-icons/ai';
import authService from './api-authorization/AuthorizeService';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import FormDetailsPopup from './FormDetailsPopup'
import ErrorMsg from './ErrorMsg'
import { saveAs } from 'file-saver';
import { AiOutlinePauseCircle } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';
import i18n from 'i18next';

export class Forms extends Component {

    constructor(props) {
        super(props);
        this.state = {
            forms: [],
            formDetails: {},
            finishedForms: [],
            orgForms: [],
            orgFinishedForms: [],
            formsCount: [],
            loading: true,
            onlyFinished: false,
            requestInProgress: false,
            showFormDetailsPopup: false,
            errorMsg: '',
        };
    }

    componentDidMount() {
        this.populateData();
    }

    toggleCheckbox = () => {
        this.setState({ ...this.state, onlyFinished: !this.state.onlyFinished });
    }

    openForm = (id) => {
        this.setState({ ...this.state, requestInProgress: true })
        this.downloadFormDetails(id);
    }

    closePopup = () => {
        this.setState({
            ...this.state,
            showFormDetailsPopup: false,
        });
    }

    errorMsgClose = () => {
        this.setState({
            ...this.state,
            errorMsg: '',
            requestInProgress: false
        });
    }

    acceptForm = (form) => {
        this.sendAcceptRequest(form.id);
        this.props.updateUserPanelCallback();
    }

    acceptFormOnResults = (id) => {
        this.updateForms(id, 1);
    }

    rejectForm = (form) => {
        this.sendRejectRequest(form.id);
        this.props.updateUserPanelCallback();
    }

    rejectFormOnResults = (id) => {
        this.updateForms(id, 2);
    }

    updateForms(id, newStatus) {
        const newList = this.state.forms.filter((form) => form.formId !== id);
        const changedElement = this.state.forms.find((form) => form.formId === id);
        changedElement.status = newStatus;

        this.setState({ ...this.state, requestInProgress: false, showFormDetailsPopup: false, forms: newList, finishedForms: this.state.finishedForms.concat(changedElement) });
    }

    handleNameChange = (e) => {
        let filteredForms = [];
        let filteredFinishedForms = [];

        if (e.target.value) {
            filteredForms = this.state.orgForms.filter(form => {
                return form.agentId.toString().toLowerCase().includes(e.target.value.toLowerCase())
                    || form.agentName.toString().toLowerCase().includes(e.target.value.toLowerCase());
            });

            filteredFinishedForms = this.state.orgFinishedForms.filter(form => {
                return form.agentId.toString().toLowerCase().includes(e.target.value.toLowerCase())
                    || form.agentName.toString().toLowerCase().includes(e.target.value.toLowerCase());
            });

        } else {
            filteredForms = this.state.orgForms;
            filteredFinishedForms = this.state.orgFinishedForms;
        }

        this.setState({ ...this.state, forms: filteredForms, finishedForms: filteredFinishedForms });
    }

    renderForms(forms, finishedForms, formsCount) {
        return (
            <div className="form-list-wrapper">
                <div className="d-flex flex-row justify-content-between">
                    <div className="table-tab-header-static text-begin">
                    {i18n.t('forms')} ({formsCount})
                    </div>
                    <NavLink tag={Link} to="/"><GrClose role="button" /></NavLink>

                </div>
                <div className="mt-20">
                    <span className="table-header">{i18n.t('agent seller')}</span>
                </div>
                <div className="d-flex flex-row justify-content-between">
                    <div>
                        <div className="relative-wrapper table-input-wrapper-wide">
                            <input className="table-header-input w-245" placeholder={i18n.t("Type name lastname")} onChange={(e) => { this.handleNameChange(e) }} />
                            <AiOutlineSearch />
                        </div>
                    </div>

                    <div className="d-flex flex-row align-items-center checkbox-container">
                        <div className={this.state.onlyFinished ? "custom-checkbox custom-checkbox-active mr6" : "custom-checkbox mr6"} onClick={() => this.toggleCheckbox()} />
                        <label className="sub-header">{i18n.t('show completed rejected only')}</label>
                    </div>
                    <div className="raport-button d-flex flex-row align-items-center" onClick={() => this.downloadReport()}>
                        <AiOutlineDownload />
                        {i18n.t('report')}
                    </div>
                </div>
                {
                    !this.state.onlyFinished
                        ? <div className="d-flex flex-column">
                            {forms.map((form, index) => (
                                <div className="d-flex flex-row justify-content-between form-row" key={index}>
                                    <div className="d-flex flex-row align-items-center" style={{color: form.suspended ? "gray" : ""}}>
                                        <span className="circle mr-20"></span><span className="fw-bold mr6">{form.agentId}</span> <span>{form.agentName}</span>
                                        {form.suspended ? 
                                            <span style={{marginLeft: "5px"}}>
                                                <AiOutlinePauseCircle data-tip={i18n.t('activity suspended')}/>
                                                <ReactTooltip type="light" html={true} />
                                            </span>
                                            : null}
                                    </div>
                                    <div className="user-details-icon" onClick={() => this.openForm(form.formId)}><AiOutlineEye /></div>
                                </div>
                            ))}
                        </div>

                        : null
                }
                {
                    finishedForms?.length && !this.state.onlyFinished
                        ? <div className="horizontal-separator" />
                        : null
                }
                {
                    finishedForms?.length && !this.state.onlyFinished
                        ? <div className="table-separator">
                            {i18n.t('completed rejected')}
                            </div>
                        : null
                }
                <div className="d-flex flex-column">
                    {finishedForms.map((form, index) => (
                        <div className="d-flex flex-row justify-content-between form-row" key={index}>
                            <div className="d-flex flex-row align-items-center" style={{color: form.suspended ? "gray" : ""}}>
                                <span className={form.status == 1 ? "green-circle mr-20" : "red-circle mr-20"}></span><span className="fw-bold mr6">{form.agentId}</span> <span>{form.agentName}</span>
                                {form.suspended ? 
                                    <span style={{marginLeft: "5px"}}>
                                        <AiOutlinePauseCircle data-tip={i18n.t('activity suspended')}/>
                                        <ReactTooltip type="light" html={true} />
                                    </span> 
                                    : null}
                            </div>
                            <div className="user-details-icon" onClick={() => this.openForm(form.formId)}><AiOutlineEye /></div>
                        </div>
                    ))}
                </div>

                {this.state.requestInProgress && <div className="overlay" />}

                {this.state.showFormDetailsPopup ?
                    <FormDetailsPopup
                        formDetails={this.state.formDetails}
                        requestInProgress={this.state.requestInProgress}
                        closePopup={this.closePopup}
                        mainAction={this.acceptForm}
                        secondaryAction={this.rejectForm}
                    />
                    : null
                }

                {this.state.errorMsg ?
                    <ErrorMsg
                        errorMsg={this.state.errorMsg}
                        close={this.errorMsgClose} />
                    : null
                }
            </div>
        );
    }

    renderLoader() {
        return (
            <div className="center-table-cell baner-wrapper-no-padding">
                <Audio
                    height="50"
                    width="50"
                    color='grey'
                    ariaLabel='loading'
                    wrapperClass='d-block'
                />
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? this.renderLoader()
            : this.renderForms(this.state.forms, this.state.finishedForms, this.state.formsCount);

        return (
            <div>
                {contents}
            </div>
        );
    }

    async populateData() {
        const token = await authService.getAccessToken();
        const response = await fetch('forms', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();

        let forms = data.forms.filter(form => {
            return form.status === 0;
        });

        let finishedForms = data.forms.filter(form => {
            return form.status !== 0;
        });

        this.setState({
            forms: forms,
            finishedForms: finishedForms,
            orgForms: forms,
            orgFinishedForms: finishedForms,
            formsCount: data.count,
            loading: false
        });
    }

    async downloadFormDetails(formId) {
        const encodedId = encodeURIComponent(formId);

        const token = await authService.getAccessToken();
        const response = await fetch(`forms/${encodedId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();

        this.setState({
            formDetails: data,
            requestInProgress: false,
            showFormDetailsPopup: true,
        });
    }

    async sendAcceptRequest(formId) {
        this.setState({ ...this.state, requestInProgress: true });
        const encodedId = encodeURIComponent(formId);
        const token = await authService.getAccessToken();

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        fetch(`forms/accept/${encodedId}`, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.message) {
                    this.setState({ ...this.state, errorMsg: data.message })
                } else {
                    this.closePopup();
                    this.acceptFormOnResults(formId);
                }
            })
            .catch(error => console.log(error));
    }

    downloadReport = async () => {
        this.setState({ ...this.state, requestInProgress: true });
        const token = await authService.getAccessToken();

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        try {
            const response = await fetch('forms/report', requestOptions)
            if (response.status !== 200) {
                var data = await response.json();
                this.setState({ ...this.state, errorMsg: data.message, requestInProgress: false });
            } else {
                var blob = await response.blob();
                saveAs(blob, 'Report.xlsx');
                this.setState({ ...this.state, requestInProgress: false });
            }
        } catch (error) {
            console.log('error: ')
            this.setState({ ...this.state, requestInProgress: false });
        }
    }

    async sendRejectRequest(formId) {
        this.setState({ ...this.state, requestInProgress: true });
        const encodedId = encodeURIComponent(formId);
        const token = await authService.getAccessToken();

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        const response = await fetch(`forms/reject/${encodedId}`, requestOptions);
        const data = await response.json();

        if (data) {
            this.closePopup();
            this.rejectFormOnResults(formId);
        }
    }
}
