import React, { useState, useRef } from 'react';
import '../custom.css';
import { GrClose } from 'react-icons/gr';
import i18n from 'i18next';

const RenderCloseButton = (props) => {
    return (
        <div className="d-flex flex-row mt-auto justify-content-end">
            <div>
                <button className="finish-action-btn button button-grey" onClick={props.closePopup}>
                {i18n.t('close')}
                 </button>
            </div>
        </div>
    );
}

const RenderActionButtons = (props, file, fileName) => {
    return (
        <div className="d-flex flex-row mt-auto justify-content-end">
            <div >
                <button className="finish-action-btn button button-transparent" onClick={props.closePopup}>
                {i18n.t('cancel')}
                </button>
            </div>
            <div>
                <button className="finish-action-btn button button-red" onClick={() => { props.updateAdditionalInfo(file, fileName) }} disabled={!file}>
                {i18n.t('save')}
                 </button>
            </div>
        </div>
    );
}

const RenderFilePicker = (onUploadFileChange) => {
    const myRefname = useRef(null);
    const handleClick = () => {
        myRefname.current.click();
    }

    return (
        <div>
            <input ref={myRefname} type="file" onChange={onUploadFileChange} accept="application/pdf" style={{ display: "none" }} />
            <button className="finish-action-btn button button-red" onClick={handleClick}>{i18n.t('select pdf')} </button>
        </div>

    );
}

const UploadAdditionalInfoPopup = (props) => {

    const popupHeader = props.updateAgreementResponse ? i18n.t('information updated') : i18n.t('add additional info pdf');

    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState({name: props.fileName});

    const fileToBase64 = (file, cb) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
            cb(null, reader.result)
        }
        reader.onerror = function (error) {
            cb(error, null)
        }
    }

    const onUploadFileChange = ({ target }) => {
        if (target.files < 1 || !target.validity.valid) {
            return
        }
        fileToBase64(target.files[0], (err, result) => {
            if (result) {
                setFile(result)
                setFileName(target.files[0])
            }
        })
    }

    return (
        <div className='popup'>
            <div className='popup-open d-flex flex-column'>
                <div className="d-flex flex-row justify-content-between" style={{ marginBottom: "30px" }}>
                    <div className={props.updateAgreementResponse ? "popup-header text-green" : "popup-header"}>
                        {popupHeader}
                    </div>
                    <div className="popup-close-icon-wrapper d-flex" style={{height: "33px"}}>
                        <GrClose role="button" onClick={props.closePopup} />
                    </div>
                </div>

                <div className="popup-content d-flex flex-column">
                    {RenderFilePicker(onUploadFileChange)}

                    {fileName && !props.updateAgreementResponse ?
                        <div className="mt-4">
                            {i18n.t('selected file')} <span className="font-bold">{fileName.name}</span>
                        </div>
                        : ""
                    }
                    {props.showRemoveButton ?
                        <div>
                            <br/>
                            <button className="finish-action-btn button button-red" onClick={props.removeAdditionalInfo}>{i18n.t('remove pdf file')} </button>
                        </div>
                    : null}
                </div>
                {props.updateAgreementResponse ? RenderCloseButton(props) : RenderActionButtons(props, file, fileName.name)}
            </div>

            {props.requestInProgress && <div className="overlay" />}
        </div>
    );
}

export default UploadAdditionalInfoPopup;