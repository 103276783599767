import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import './custom.css';
import { Audio } from 'react-loader-spinner'
import { Header } from './components/Header';
import { UserInfoWrapper } from './components/UserInfoWrapper';
import { BannersWrapper } from './components/BannersWrapper';
import { NavMenu } from './components/NavMenu';
import authService from './components/api-authorization/AuthorizeService'
import AgreementPopup from './components/AgreementPopup';


export default class App extends Component {

    constructor(props) {
        super(props);
        this.state = { config: {}, isConfigLoaded: false };
    }

    componentDidMount() {
        this.populateData();
    }

    renderLoader() {
        return (
            <div className="center-table-cell">
                <Audio
                    height="50"
                    width="50"
                    color='grey'
                    ariaLabel='loading'
                    wrapperClass='d-block'
                />
            </div>
        );
    }

    acceptAgreement = async () => {
        const token = await authService.getAccessToken();
        const jsonData = { id: this.state.config.agreementId };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(jsonData)
        };
        fetch('agreement', requestOptions)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    ...this.state,
                    config: { ...this.state.config, isRegulationApprovalRequired: false, regulationsLastAcceptDate: data.acceptDate }
                });
            });
    }

    updateUserPanelCallback = () => {
        console.log('callbakc: ');
        this.setState({ ...this.state, updateForced: true });
    }

    renderApp() {
        return (
            <div>
                {/* <NavMenu /> */}
                <Header />
                <div className="row">
                    <div className="col-2">
                        <UserInfoWrapper
                            {...this.state}
                        />
                    </div>
                    <div className="col-6">
                        <Routes>
                            {AppRoutes.map((route, index) => {
                                const { element, requireAuth, ...rest } = route;
                                const elementWithConfig = React.cloneElement(element, { ...this.state, updateUserPanelCallback: this.updateUserPanelCallback });
                                return <Route key={index} {...rest} element={requireAuth ? <AuthorizeRoute {...rest} element={elementWithConfig} /> : elementWithConfig} />;
                            })}
                        </Routes>
                    </div>
                    <div className="col-4">
                        <BannersWrapper {...this.state} />
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.isLoginUrl() ?
                    this.renderApp()
                    : !this.state.isConfigLoaded
                        ? this.renderLoader()
                        : this.state.config.isRegulationApprovalRequired
                            ? <AgreementPopup agreement={this.state.config.agreement} acceptAgreement={this.acceptAgreement} acceptable={true} />
                            : this.renderApp()
                }

            </div>
        );
    }

    async populateData() {
        const token = await authService.getAccessToken();

        if (!this.isLoginUrl()) {
            const response = await fetch('config', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            const data = await response.json();
            this.setState({ config: data, isConfigLoaded: true });
        }
    }

    isLoginUrl() {
        return window.location.pathname == '/authentication/login'
            || window.location.pathname == '/login-callback';
    }
}
