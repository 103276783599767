
import React, { useState, useEffect, useRef } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri'

function useOutsideAlerter(ref, callback) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && event.target.closest('.sort-select-wrapper') === null) {
                callback();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export const SortSelect = (props) => {
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
    const [optionsView, setOptionsView] = useState([])
    const [optionsFilterCriteria, setOptionsFilterCriteria] = useState('');
    const wrapperRef = useRef(null);

    const selectedOption = props.selectedValue ?? props.optionsList[selectedOptionIndex];

    useOutsideAlerter(wrapperRef, () => setIsOptionsOpen(false));

    const toggleOptions = () => {
        setIsOptionsOpen(!isOptionsOpen);
    };

    const setSelectedThenCloseDropdown = (option) => {
        if (selectedOption != option) {
            let index = props.optionsList.findIndex(o => o === option);
            setSelectedOptionIndex(index);
            props.setSelectedOption(index);
        }
        setIsOptionsOpen(false);
        setOptionsFilterCriteria('');
    };

    const reloadOptions = (filterCriteria) => {
        setOptionsFilterCriteria(filterCriteria);
        setOptionsView(props.optionsList.filter(option => option.includes(filterCriteria)))
    }

    useEffect(() => {
        setOptionsView(props.optionsList);
    }, [props.optionsList])

    const handleKeyDown = (e) => {
        if (e.key == "Enter" && optionsView?.length == 1) {
            e.preventDefault();
            setSelectedThenCloseDropdown(optionsView[0])
        }
    };
    return (
        <div className="sort-select-wrapper" ref={wrapperRef}>
            <button
                type="button"
                aria-haspopup="listbox"
                aria-expanded={isOptionsOpen}
                className="select-sort-button"
                onClick={toggleOptions}>
                <div className="d-flex flex-row justify-content-between">
                    <div className="sort-option-empty font-bold">
                        {selectedOption}
                    </div>
                    <div>
                        <RiArrowDropDownLine />
                    </div>
                </div>
            </button>
            <ul
                className={`options ${isOptionsOpen ? "show" : ""}`}
                aria-activedescendant={selectedOption}>
                {
                    props.searchable &&
                    <input ref={input => input && input.focus()}
                        className="sort-select-search-input"
                        onChange={(filterCriteria) => reloadOptions(filterCriteria.target.value)}
                        value={optionsFilterCriteria}
                        onKeyDown={handleKeyDown}
                    />
                }
                {optionsView.map((option, index) => (
                    <li key={index} aria-selected={selectedOption == option}
                        onClick={() => { setSelectedThenCloseDropdown(option); }}>
                        <div className={"sort-option-empty " + (selectedOption == option ? "font-bold" : "")}>
                            {option}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};
