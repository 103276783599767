import React, { useState, forwardRef } from 'react';
import '../../../custom.css';
import { GrClose } from 'react-icons/gr';
import DatePicker, { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl";
import i18n from 'i18next';
registerLocale("pl", pl);

const RenderActionButtons = (props, updateCallback, canUpdate) => {
    return (
        <div className="d-flex flex-row mt-auto justify-content-end">
            <div >
                <button className="finish-action-btn button button-transparent mr6" onClick={props.closePopup}>
                {i18n.t('cancel')}
                </button>
            </div>
            <div>
                <button className="finish-action-btn button button-red" onClick={updateCallback} disabled={!canUpdate}>
                {i18n.t('update')}
                 </button>
            </div>
        </div>
    );
}

const PointsUpdatePopup = (props) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [newPoints, setNewPoints] = useState(0);
    const [comment, setComment] = useState('');
    const [dateVisible, setDateVisible] = useState(true);
    const [expirationDate, setExpirationDate] = useState(new Date());

    const updateCallback = () => {
        props.updatePoints({
            currentDate: currentDate,
            earnedPoints: newPoints,
            comment: comment,
            expirationDate: expirationDate
        })
    }

    const pointsChangeHandler = (inputValue) => {
        if (new RegExp(/^(?:-?\d*)?$/).test(inputValue)) {
            setNewPoints(inputValue);
        }

        setDateVisible(!(inputValue < 0));
    }

    const DataPickerInput = forwardRef(({ value, onClick }, ref) => (
        <input type="text" className="form-input" value={value} onClick={onClick} ref={ref} ></input>
    ));

    return (
        <div className='popup'>
            <div className='popup-open d-flex flex-column'>
                <div className="d-flex flex-row justify-content-between" style={{ marginBottom: "30px" }}>
                    <div className="popup-header">
                    {i18n.t('edit number of points')}
                    </div>
                    <div className="popup-close-icon-wrapper d-flex">
                        <GrClose role="button" onClick={props.closePopup} />
                    </div>
                </div>

                <div className="popup-content d-flex flex-column add-note-content">
                    <div className="d-flex flex-row" style={{ marginBottom: "15px" }}>
                        <div className="wp-35">
                        {i18n.t('seller')}
                        </div>
                        <div className="wp-65 font-bold">
                            {props.name}
                        </div>
                    </div>
                    <div className="d-flex flex-row" style={{ marginBottom: "15px" }}>
                        <div className="wp-35">
                        {i18n.t('date')}
                        </div>
                        <div className="wp-65 font-bold">
                            {currentDate.toLocaleString() + ""}
                        </div>
                    </div>
                    {
                        dateVisible ? <div className="d-flex flex-row" style={{ marginBottom: "15px" }}>
                            <div className="wp-65">
                            {i18n.t('points expire date')}
                            </div>
                            <div className="wp-35 font-bold">
                                <DatePicker
                                    yearDropdownItemNumber={250}
                                    scrollableYearDropdown={true}
                                    showYearDropdown={true}
                                    locale={i18n.t('locale')}
                                    dateFormat="dd.MM.yyyy"
                                    selected={expirationDate}
                                    onChange={(date) => setExpirationDate(date)}
                                    customInput={<DataPickerInput />}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                />
                            </div>
                        </div>
                            : null
                    }

                    <div className="d-flex flex-row" style={{ marginBottom: "15px" }}>
                        <div className="wp-65">
                        {i18n.t('number of accured points')}
                        </div>
                        <div className="wp-35">
                            <input type="text" className="form-input" value={newPoints} onChange={(e) => pointsChangeHandler(e.target.value)}></input>
                        </div>
                    </div>
                    <div className="d-flex flex-row">
                        <div className="wp-35">
                        {i18n.t('comment')}
                        </div>
                        <div className="wp-65 font-bold">
                            <textarea className="add-note-textarea"
                                onChange={(e) => setComment(e.target.value)}
                                maxLength="200"
                                placeholder={i18n.t("type")}
                            />
                        </div>
                    </div>
                    <div className="user-details-date" style={{ "textAlign": "end", "marginBottom": "20px" }}>
                    {i18n.t('max 200 chars')}
                    </div>
                </div>

                {RenderActionButtons(props, updateCallback, newPoints != 0)}
            </div>
        </div>
    );
}

export default PointsUpdatePopup;