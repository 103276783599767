import React, { Component } from 'react';
import '../custom.css';
import { Audio } from 'react-loader-spinner'
import { AiFillStar, AiOutlineSearch, AiOutlineCalendar, AiOutlineEye, AiOutlineDownload } from 'react-icons/ai';
import { FaArrowsAltV, FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa';
import authService from './api-authorization/AuthorizeService'
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import DatePicker, { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl";
import { saveAs } from 'file-saver';
import { AiOutlinePauseCircle } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';
import i18n from 'i18next';
registerLocale("pl", pl);

export class UserList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: {},
            loading: true,
            nameSortOrder: '',
            earnedPointsSortOrder: '',
            potPointsSortOrder: '',
            potDateSortOrder: '',
            dateFromSearch: '',
            dateToSearch: '',
            agentsSearch: ''
        };
    }

    componentDidMount() {
        this.populateData();
    }

    sortColumn = (column) => {
        let currentOrder = ''
        switch (column) {
            case 'name':
                currentOrder = this.state.nameSortOrder;
                this.setState({
                    ...this.state,
                    earnedPointsSortOrder: '',
                    potPointsSortOrder: '',
                    potDateSortOrder: '',
                    nameSortOrder: currentOrder !== 'asc' ? 'asc' : 'dsc',
                    loading: true
                },
                    this.refreshData)
                break;
            case 'earnedPoints':
                currentOrder = this.state.earnedPointsSortOrder;
                this.setState({
                    ...this.state,
                    nameSortOrder: '',
                    potPointsSortOrder: '',
                    potDateSortOrder: '',
                    earnedPointsSortOrder: currentOrder !== 'asc' ? 'asc' : 'dsc',
                    loading: true
                },
                    this.refreshData)
                break;
            case 'potPoints':
                currentOrder = this.state.potPointsSortOrder;
                this.setState({
                    ...this.state,
                    nameSortOrder: '',
                    earnedPointsSortOrder: '',
                    potDateSortOrder: '',
                    potPointsSortOrder: currentOrder !== 'asc' ? 'asc' : 'dsc',
                    loading: true
                },
                    this.refreshData)
                break;
            case 'potDate':
                currentOrder = this.state.potDateSortOrder;
                this.setState({
                    ...this.state,
                    nameSortOrder: '',
                    earnedPointsSortOrder: '',
                    potPointsSortOrder: '',
                    potDateSortOrder: currentOrder !== 'asc' ? 'asc' : 'dsc',
                    loading: true
                },
                    this.refreshData)
                break;
        }
    }

    handleAgentsSearchChange = (search) => {
        this.setState({ ...this.state, agentsSearch: search })
    }

    handleClickSearch = () => {
        this.setState({ ...this.state, loading: true }, this.refreshData)
    }

    refreshData = () => {
        this.populateData(true)
    }

    setFromDate = (date) => {
        date.setTime(date.getTime() + 2 * 60 * 60 * 1000);

        this.setState({ ...this.state, dateFromSearch: date, loading: true }, this.refreshData)
    }

    setToDate = (date) => {
        date.setTime(date.getTime() + 2 * 60 * 60 * 1000);
        this.setState({ ...this.state, dateToSearch: date, loading: true }, this.refreshData)
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.setState({ ...this.state, loading: true }, this.refreshData)
        }
    }

    renderUsers(users) {
        return (
            <div className="list-wrapper">
                <div className="d-flex flex-row justify-content-between">
                    <div className="table-tab-header-static text-begin">
                    {i18n.t('members and points')}
                    </div>
                    <div className="raport-button" onClick={() => this.downloadReport()}>
                        <AiOutlineDownload />
                        {i18n.t('report of points')}
                    </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                <div>
                                    <span className="table-header" onClick={() => { this.sortColumn('name') }}>{i18n.t('agent seller')}
                                    {
                                            this.state.nameSortOrder === ''
                                                ? <FaArrowsAltV style={{ color: "#808085" }} />
                                                : this.state.nameSortOrder === 'asc'
                                                    ? <FaLongArrowAltDown style={{ color: "black" }} />
                                                    : <FaLongArrowAltUp style={{ color: "black" }} />
                                        }</span>
                                    <div className="relative-wrapper table-input-wrapper-wide">
                                        <input value={this.state.agentsSearch} className="table-header-input w-245" placeholder={i18n.t("Type name lastname")} onChange={(e) => { this.handleAgentsSearchChange(e.target.value) }} onKeyDown={this.handleKeyDown} />
                                        <AiOutlineSearch onClick={() => this.handleClickSearch()} />
                                    </div>
                                </div>
                            </th>
                            <th className="text-center table-header" onClick={() => { this.sortColumn('earnedPoints') }}>{i18n.t('accured points')} {
                                this.state.earnedPointsSortOrder === ''
                                    ? <FaArrowsAltV style={{ color: "#808085" }} />
                                    : this.state.earnedPointsSortOrder === 'asc'
                                        ? <FaLongArrowAltDown style={{ color: "black" }} />
                                        : <FaLongArrowAltUp style={{ color: "black" }} />
                            }</th>

                            <th className="text-center table-header" onClick={() => { this.sortColumn('potPoints') }}>{i18n.t('potential points')} {
                                this.state.potPointsSortOrder === ''
                                    ? <FaArrowsAltV style={{ color: "#808085" }} />
                                    : this.state.potPointsSortOrder === 'asc'
                                        ? <FaLongArrowAltDown style={{ color: "black" }} />
                                        : <FaLongArrowAltUp style={{ color: "black" }} />
                            }</th>
                            <th>
                                <div >
                                    <span className="table-header" onClick={() => { this.sortColumn('potDate') }}>{i18n.t('counting point date range')} {
                                        this.state.potDateSortOrder === ''
                                            ? <FaArrowsAltV style={{ color: "#808085" }} />
                                            : this.state.potDateSortOrder === 'asc'
                                                ? <FaLongArrowAltDown style={{ color: "black" }} />
                                                : <FaLongArrowAltUp style={{ color: "black" }} />
                                    }</span>
                                    <div className="relative-wrapper table-input-wrapper-mid">
                                        <div className="d-flex flex-row">
                                            <div className="datepicker-wrapper">
                                                <DatePicker
                                                    placeholderText={i18n.t("from")}
                                                    locale={i18n.t('locale')}
                                                    dateFormat="dd.MM.yyyy"
                                                    selected={this.state.dateFromSearch}
                                                    onChange={(date) => this.setFromDate(date)}
                                                    yearDropdownItemNumber={250}
                                                    scrollableYearDropdown={true}
                                                    showYearDropdown={true}
                                                    onKeyDown={(e) => {
                                                        e.preventDefault();
                                                    }} />
                                            </div>
                                            <div className="datepicker-wrapper">
                                                <DatePicker
                                                    placeholderText={i18n.t("to")}
                                                    locale={i18n.t('locale')}
                                                    dateFormat="dd.MM.yyyy"
                                                    selected={this.state.dateToSearch}
                                                    onChange={(date) => this.setToDate(date)}
                                                    yearDropdownItemNumber={250}
                                                    scrollableYearDropdown={true}
                                                    showYearDropdown={true}
                                                    onKeyDown={(e) => {
                                                        e.preventDefault();
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user, index) => (
                            <tr className="main-table" key={index}>
                                <td className="pl-28" style={{color: user.suspended ? "gray" : ""}}>
                                    <span className="fw-bold">{user.number}</span> <span>{user.name}</span>
                                    {user.suspended ? <span style={{marginLeft: "5px"}}><AiOutlinePauseCircle data-tip={i18n.t('activity suspended')}/>
                                    <ReactTooltip type="light" html={true} /></span> : null}
                                </td>
                                <td className={user.promoEarnedPoints ? "text-center text-green" : "text-center"}>
                                    {user.earnedPoints} {user.promoEarnedPoints ? <AiFillStar /> : null}
                                </td>
                                <td className={user.promoPotentialPoints ? "text-center text-green" : "text-center"}>
                                    {user.potentialPoints} {user.promoPotentialPoints ? <AiFillStar /> : null}
                                </td>
                                <td>
                                    <div className="d-flex flex-row justify-content-between align-items-center pr-15">
                                        <div>{user.pointsDate}</div>
                                        <div className="user-details-icon">
                                            <NavLink tag={Link} to={"/user-details/" + user.id}>
                                                <AiOutlineEye role="button" />
                                            </NavLink>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            {this.state.requestInProgress && <div className="overlay" />}
            </div >            
        );
    }

    renderLoader() {
        return (
            <div className="center-table-cell baner-wrapper-no-padding">
                <Audio
                    height="50"
                    width="50"
                    color='grey'
                    ariaLabel='loading'
                    wrapperClass='d-block'
                />
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? this.renderLoader()
            : this.renderUsers(this.state.usersView);

        return (
            <div>
                {contents}
            </div>
        );
    }

    async populateData(sendCriteria) {
        const token = await authService.getAccessToken();
        const jsonData = sendCriteria ? this.prepareCriteria() : { model: {} };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(jsonData)
        };

        fetch('admin/users', requestOptions)
            .then(response => response.json())
            .then(data => {
                this.setState({ users: data, usersView: data, loading: false })
            });
    }

    prepareCriteria = () => {
        return {
            agentSortOrder: this.state.nameSortOrder,
            earnedPointsSortOrder: this.state.earnedPointsSortOrder,
            potentialPointsSortOrder: this.state.potPointsSortOrder,
            dateSortOrder: this.state.potDateSortOrder,
            agentSearch: this.state.agentsSearch,
            dateFromSearch: this.state.dateFromSearch,
            dateToSearch: this.state.dateToSearch
        }
    }

    downloadReport = async () => {
        this.setState({ ...this.state, loading: true });
        const token = await authService.getAccessToken();
        const jsonData = this.prepareCriteria();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(jsonData)
        };

        try {
            const response = await fetch('admin/report', requestOptions)
            if (response.status !== 200) {
                var data = await response.json();
                this.setState({ ...this.state, errorMsg: data.message, loading: false });
            } else {
                var blob = await response.blob();
                saveAs(blob, 'Report.xlsx');
                this.setState({ ...this.state, loading: false });
            }
        } catch (error) {
            console.log('error: ')
            this.setState({ ...this.state, loading: false });
        }
    }
}
