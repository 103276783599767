import React, { useState } from 'react';
import '../custom.css';
import { Document, Page } from 'react-pdf'
import i18n from 'i18next';

const RenderAcceptButton = (props) => {
    return (
        <div className="d-flex flex-row mt-auto justify-content-end">
            <div>
                <button className={"}finish-action-btn button " + (props.acceptable ? "button-red" : "button-grey")} onClick={props.acceptAgreement}>
                    {props.acceptable ? i18n.t('accept') : i18n.t('close')}
                </button>
            </div>
        </div>
    );
}

const AgreementPopup = (props) => {
    return (
        <div className='popup'>
            <div className='popup-open-agreement d-flex flex-column'>
                <div className="d-flex flex-row justify-content-between" style={{ marginBottom: "30px" }}>
                    <div className="popup-header">
                    {i18n.t('accept regulations')}
                    </div>
                </div>

                <div className="popup-content d-flex flex-column">
                    {/* <Document file={`data:application/pdf;base64,${props.agreement}`}>
                        <Page pageNumber={1} />
                    </Document> */}
                    <object data={`data:application/pdf;base64,${props.agreement}`} >
                        <embed src={`data:application/pdf;base64,${props.agreement}`} />
                    </object>
                </div>
                {RenderAcceptButton(props)}
            </div>
        </div>
    );
}

export default AgreementPopup;