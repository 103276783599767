import React, { useState, useRef } from 'react';
import '../../../custom.css';
import { GrClose } from 'react-icons/gr';
import DatePicker, { registerLocale } from "react-datepicker";
import i18n from 'i18next';

const RenderActionButtons = (props, endDate) => {
    return (
        <div className="d-flex flex-row mt-auto justify-content-end">
            <div >
                <button className="finish-action-btn button button-transparent mr6" onClick={props.closePopup}>
                {i18n.t('cancel')}
                </button>
            </div>
            <div>
                <button className="finish-action-btn button button-red" onClick={() => props.unsuspend(endDate)}>
                {i18n.t('_save')}
                 </button>
            </div>
        </div>
    );
}

const UnsuspendUserPopup = (props) => {
    const [endDate, setEndDate] = useState(new Date());    

    const handleEndDateChange = date => {
        setEndDate(date);
    };

    const dateOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      };
    return (
        <div className='popup'>
            <div className='popup-open d-flex flex-column unsuspend-user-popup'>
                <div className="d-flex flex-row justify-content-between" style={{ marginBottom: "30px" }}>
                    <div className="popup-header">
                    {i18n.t('check unsuspend date')}
                        <div className="user-details-popup-name">
                            <b>{props.userDetails.firstName} {props.userDetails.lastName} </b>
                             {props.userDetails.number}
                        </div>
                    </div>
                    <div className="popup-close-icon-wrapper d-flex" style={{height: "33px"}}>
                        <GrClose role="button" onClick={props.closePopup} />
                    </div>
                </div>

                <div className="popup-content d-flex flex-column add-note-content">
                    <div className="d-flex flex-row" style={{ marginBottom: "15px" }}>
                        <div className="base-form-input-label">
                        {i18n.t('activity suspend date')} <b>{new Date(props.userDetails.suspendedDate).toLocaleTimeString(undefined, dateOptions)}</b>
                        </div>
                    </div>
                    <div className="d-flex flex-row" style={{ marginBottom: "15px" }}>
                        <div style={{ width: "100%" }}>
                        {i18n.t('unsuspend date')}
                        </div>
                        <DatePicker
                            yearDropdownItemNumber={250}
                            scrollableYearDropdown={true}
                            showYearDropdown={true}
                            timeInputLabel="Godzina:"
                            showTimeInput
                            locale={i18n.t('locale')}
                            dateFormat="dd.MM.yyyy HH:mm"
                            selected={endDate}
                            onChange={(date) => handleEndDateChange(date)}
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            />
                    </div>
                </div>

                {RenderActionButtons(props, endDate)}
            </div>
        </div>
    );
}

export default UnsuspendUserPopup;