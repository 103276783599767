import React from 'react';
import '../custom.css';
import { GrClose } from 'react-icons/gr';
import { useFilePicker } from 'use-file-picker';
import i18n from 'i18next';

const RenderCloseButton = (props) => {
    return (
        <div className="d-flex flex-row mt-auto justify-content-end">
            <div>
                <button className="finish-action-btn button button-grey" onClick={props.closePopup}>
                {i18n.t('close')}
                 </button>
            </div>
        </div>
    );
}

const RenderActionButtons = (props, filesContent) => {
    return (
        <div className="d-flex flex-row mt-auto justify-content-end">
            <div >
                <button className="finish-action-btn button button-transparent" onClick={props.closePopup}>
                {i18n.t('cancel')}
                </button>
            </div>
            <div>
                <button className="finish-action-btn button button-red" onClick={() => { props.saveCsv(filesContent[0].content) }} disabled={!filesContent[0]}>
                {i18n.t('save')}
                 </button>
            </div>
        </div>
    );
}

const RenderFilePicker = (openFileSelector, promos) => {
    return (
        <div className="d-flex flex-row justify-content-between">
            <button className="finish-action-btn button button-red" onClick={() => openFileSelector()}>{i18n.t('select csv file')} </button>
            <div><a href={`data:text/csv;base64,${promos}`} download="Promo.csv">{i18n.t('current spetial offers')}</a></div>
        </div>
    );
}

const RenderCsvUploadResult = (csvUploadResponse) => {
    return (
        <div>
            <div>
            {i18n.t('added special offers')} <span className="font-bold">{csvUploadResponse.added}</span>
            </div>
            <div>
            {i18n.t('updated special offers')} <span className="font-bold">{csvUploadResponse.updated}</span>
            </div>
            <div>
            {i18n.t('deleted special offers')} <span className="font-bold">{csvUploadResponse.removed}</span>
            </div>
        </div>
    );
}

const UploadBonusListPopup = (props) => {
    const [openFileSelector, { filesContent }] = useFilePicker({
        accept: '.csv',
        multiple: false
    });

    const popupHeader = props.bonusCsvUploadResponse ? i18n.t('special offer file added') : i18n.t('add special offer file');

    return (
        <div className='popup'>
            <div className='popup-open d-flex flex-column'>
                <div className="d-flex flex-row justify-content-between" style={{ marginBottom: "30px" }}>
                    <div className={props.bonusCsvUploadResponse ? "popup-header text-green" : "popup-header"}>
                        {popupHeader}
                    </div>
                    <div className="popup-close-icon-wrapper d-flex">
                        <GrClose role="button" onClick={props.closePopup} />
                    </div>
                </div>

                <div className="popup-content d-flex flex-column">
                    {props.bonusCsvUploadResponse ? RenderCsvUploadResult(props.bonusCsvUploadResponse) : RenderFilePicker(openFileSelector, props.currentPromotions)}

                    {filesContent[0] && !props.bonusCsvUploadResponse ?
                        <div className="mt-4">
                            {i18n.t('selected file')} <span className="font-bold">{filesContent[0]?.name}</span>
                        </div>
                        : ""
                    }
                </div>
                {props.bonusCsvUploadResponse ? RenderCloseButton(props) : RenderActionButtons(props, filesContent)}
            </div>

            {props.requestInProgress && <div className="overlay" />}
        </div>
    );
}

export default UploadBonusListPopup;