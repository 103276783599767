import React, { Component } from 'react';
import '../custom.css';
import logo from '../logo.png';

export class Header extends Component {
    static displayName = Header.name;

    render() {
        return (
            <div className="main">
                <img className="logo" src={logo} alt="Logo" />
            </div>
        );
    }
}
