import React, { useState } from 'react';
import '../custom.css';
import { GrClose } from 'react-icons/gr';
import i18n from 'i18next';

const RenderCloseButton = (props) => {
    return (
        <div className="d-flex flex-row mt-auto justify-content-end">
            <div>
                <button className="finish-action-btn button button-grey" onClick={props.closePopup}>
                {i18n.t('close')}
                 </button>
            </div>
        </div>
    );
}

const RenderActionButtons = (props, realizeNote) => {
    return (
        <div className="d-flex flex-row mt-auto justify-content-end">
            <div >
                <button className="finish-action-btn button button-transparent" onClick={props.closePopup}>
                {i18n.t('cancel')}
                </button>
            </div>
            <div>
                <button className="finish-action-btn button button-red" onClick={() => { props.updateRealizeNote(realizeNote) }} disabled={!realizeNote}>
                {i18n.t('_update_')}
                 </button>
            </div>
        </div>
    );
}

const RealizeNotePopup = (props) => {
    const [realizeNote, setRealizeNote] = useState(props.realizeNote);

    const handleRealizeNoteChange = event => {
        setRealizeNote(event.target.value);
    };

    const popupHeader = props.updateRealizeNoteResponse ? i18n.t('text updated') : i18n.t('how to realize reward');

    return (
        <div className='popup'>
            <div className='popup-open d-flex flex-column'>
                <div className="d-flex flex-row justify-content-between" style={{ marginBottom: "30px" }}>
                    <div className={props.updateRealizeNoteResponse ? "popup-header text-green" : "popup-header"}>
                        {popupHeader}
                    </div>
                    <div className="popup-close-icon-wrapper d-flex">
                        <GrClose role="button" onClick={props.closePopup} />
                    </div>
                </div>

                <div className="popup-content d-flex flex-column">
                    <textarea className="popup-input"
                        value={realizeNote}
                        onChange={handleRealizeNoteChange}
                        disabled={props.readonly}
                    />
                </div>

                {(props.updateRealizeNoteResponse || props.readonly) ? RenderCloseButton(props) : RenderActionButtons(props, realizeNote)}
            </div>

            {props.requestInProgress && <div className="overlay" />}
        </div>
    );
}

export default RealizeNotePopup;