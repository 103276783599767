import React, { Component } from 'react';
import { FiInfo } from 'react-icons/fi';
import '../custom.css';
import { Audio } from 'react-loader-spinner';
import RealizeNotePopup from './RealizeNotePopup';
import AgreementPopup from './AgreementPopup';
import DownloadDataApprovalPopup from './DownloadDataApprovalPopup';
import SelectRewardsListPopup from './SelectRewardsListPopup';
import authService from './api-authorization/AuthorizeService'
import FormDetailsPopup from './FormDetailsPopup'
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import ErrorMsg from './ErrorMsg'
import YesNoGenericPopup from './YesNoGenericPopup'
import GenericInfoPopup from './GenericInfoPopup'
import AdditionalInfoPopup from './AdditionalInfoPopup';
import i18n from "i18next";

export class MemberInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            member: {},
            loading: true,
            showRealizeNotePopup: false,
            showAdditionalInfoPopup: false,
            showAgreementPopup: false,
            showDownloadDataApprovalPopup: false,
            showFormDetailsPopup: false,
            showSelectRewardPopup: false,
            invalidReservation: false,
            showYesNoDialog: false,
            showGenericInfoPopup: false,
            rewardsFilterText: "",
            rewardsSort: 0,
            requestInProgress: false
        };
    }

    componentDidMount() {
        this.populateData();
    }

    onRewardListClick = () => {
        // if (this.state.member.rewardFormId > 0) {
        //     if (this.state.member.rewardFormSentAt && this.state.member.rewardFormStatus == 0) {
        //         this.toggleInfoPopup();
        //     } else {
        //         this.toggleYesNoPopup();
        //     }

        // } else {
        this.downloadRewards();
        // }
    }

    toggleRealizeNotePopup = () => {
        this.setState({
            ...this.state,
            showRealizeNotePopup: !this.state.showRealizeNotePopup,
        });
    }

    toggleAdditionalInfoPopup = () => {
        this.setState({
            ...this.state,
            showAdditionalInfoPopup: !this.state.showAdditionalInfoPopup,
        });
    }

    toggleAgreementPopup = () => {
        this.setState({
            ...this.state,
            showAgreementPopup: !this.state.showAgreementPopup,
        });
    }

    toggleYesNoPopup = () => {
        this.setState({
            ...this.state,
            showYesNoDialog: !this.state.showYesNoDialog,
        });
    }

    toggleInfoPopup = () => {
        this.setState({
            ...this.state,
            showGenericInfoPopup: !this.state.showGenericInfoPopup,
        });
    }

    toggleSelectRewardPopup = () => {
        this.setState({
            ...this.state,
            showSelectRewardPopup: !this.state.showSelectRewardPopup,
        });
    }

    toggleDownloadDataApprovalPopup = () => {
        this.setState({
            ...this.state,
            showDownloadDataApprovalPopup: !this.state.showDownloadDataApprovalPopup,
        });
    }

    toggleFormDetailsPopup = () => {
        let formDetails = {};
        if (this.state.showFormDetailsPopup) {
            formDetails = this.initFormDetails(this.state.member);
        } else {
            formDetails = this.state.formDetails;
        }

        this.setState({
            ...this.state,
            formDetails: formDetails,
            showFormDetailsPopup: !this.state.showFormDetailsPopup,
            showDownloadDataApprovalPopup: false
        });
    }

    showFormDetails = (member) => {
        if (!this.canShowFormDetails(member)) {
            return;
        }
        if (member.rewardFormId) {
            this.toggleDownloadDataApprovalPopup();
        } else {
            this.toggleFormDetailsPopup();
        }
    }

  canShowFormDetails = (member) => {
        if (!member.isRewardSelected) {
            return false;
        }

        if (!member.rewardFormSentAt || (member.rewardFormSentAt && member.rewardFormStatus == 2)) {
            return true;
        }

        return false;
    }

    markReward = (rewardId) => {
        const newRewards = this.state.rewards.rewards.map((reward) => {
            const updatedReward = {
                ...reward,
                isMarked: reward.id === rewardId,
            };

            return updatedReward;
        });

        const newOrigRewards = this.state.origRewards.rewards.map((reward) => {
            const updatedReward = {
                ...reward,
                isMarked: reward.id === rewardId,
            };

            return updatedReward;
        });

        this.setState({
            ...this.state,
            rewards: {
                ...this.state.rewards,
                rewards: newRewards
            },
            origRewards: {
                ...this.state.rewards,
                rewards: newOrigRewards
            }
        })
    }

    sortRewards = (sortType) => {
        console.log('sorttype: ', sortType)
        this.setState({ ...this.state, rewardsSort: sortType }, () => {
            this.narrowRewards();
        });
    }

    filterRewards = (event) => {
        const search = event.target.value;
        this.setState({ ...this.state, rewardsFilterText: search }, () => {
            this.narrowRewards();
        });
    }

    narrowRewards = () => {
        const search = this.state.rewardsFilterText;
        const sortType = this.state.rewardsSort;

        let narrowedRewards = this.state.origRewards.rewards.filter(reward =>
            reward.name.toLowerCase().includes(search.toLowerCase()) || reward.code.toLowerCase().includes(search.toLowerCase())
        );

        // 0 - alphabetical, 1 - points ascending, 2 - poitns descending
        switch (sortType) {
            case 0:
                narrowedRewards = narrowedRewards.sort((a, b) => a.code > b.code ? 1 : -1);
                break;
            case 1:
                narrowedRewards = narrowedRewards.sort((a, b) => a.price > b.price ? 1 : -1);
                break;
            case 2:
                narrowedRewards = narrowedRewards.sort((a, b) => a.price > b.price ? -1 : 1);
                break;
        }

        this.setState({
            ...this.state,
            rewards: {
                ...this.state.rewards,
                rewards: narrowedRewards
            }
        })
    }

    prepareRewards = (data) => {
        let preparedRewards = data.rewards.sort((a, b) => a.code > b.code ? 1 : -1);
        if (this.state.member.selectedRewardId) {
            preparedRewards = preparedRewards.map((reward) => {
                const updatedReward = {
                    ...reward,
                    isMarked: reward.id === this.state.member.selectedRewardId,
                };

                return updatedReward;
            });
        }


        this.setState({
            rewards: {
                ...data,
                rewards: preparedRewards
            },
            origRewards: {
                ...data,
                rewards: preparedRewards
            },
            requestInProgress: false
        });
    }

    isRezNumberValid = (rezNumber) => {
        if (!rezNumber) {
            return false;
        }

        if (!/^[0-9]+$/.test(rezNumber) || rezNumber.length < 7) {
            return false;
        }

        return true;
    }

    saveReward = async () => {
        const selectedReward = this.state.rewards.rewards.find((reward) => reward.isMarked);
        const encodedId = encodeURIComponent(selectedReward.id);

        const token = await authService.getAccessToken();

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        fetch(`reward/select-reward/${encodedId}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                this.setState({ loading: true });
                this.populateData();
            });
    }

    downloadReservations = async (rezNumber) => {
        if (!this.isRezNumberValid(rezNumber)) {
            return;
        }

        this.setState({ ...this.state, requestInProgress: true });

        const encodedId = encodeURIComponent(rezNumber);

        const token = await authService.getAccessToken();

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        fetch(`reservations/${encodedId}`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    this.setState({
                        formDetails: { ...this.state.formDetails, invalidReservation: true },
                        requestInProgress: false
                    });

                    throw new Error('Invalid reservation');
                }

                return response;
            })
            .then(response => {
                return response.json()
            })
            .then(data => {
                this.setState({
                    formDetails: {
                        ...this.state.formDetails,
                        reservationDownloaded: true,
                        beginDateTime: data.beginDateTime,
                        endDateTime: data.endDateTime,
                        tripMembers: data.tripMembers,
                        reservationNumber: data.reservationNumber,
                        reservationDates: data.reservationDates,

                        supplementRequired: data.supplementRequired,
                        missingPoints: data.missingPoints,
                        agentsSupplement: data.agentsSupplement,

                        agencyOfficeNumber: data.agencyOfficeNumber,
                        agencyOfficeName: data.agencyOfficeName,
                        departureDate: data.departureDate,
                        destination: data.destination,
                        systemPrice: data.systemPrice,
                        pointsRequired: data.pointsRequired,
                    },
                    requestInProgress: false
                });
            })
            .catch(error => console.log(error));
    }

    downloadPreviousData = async () => {
        const encodedId = encodeURIComponent(this.state.member.rewardFormId);

        const token = await authService.getAccessToken();

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        fetch(`forms/${encodedId}`, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.reservationNumber > 0) {
                    this.downloadReservations(data.reservationNumber);
                }

                this.setState({
                    formDetails: { ...data, percentageProgress: this.state.member.target.percentageProgress },
                    showDownloadDataApprovalPopup: false,
                    showFormDetailsPopup: true,
                });
            })
            .catch(error => console.log(error));
    }

    openEmptyRewardForm = () => {
        this.toggleFormDetailsPopup();
    }

    submitForm = async (formValues) => {
        const formData = this.prepareFormToSend(formValues);
        formData.isFinalForm = true;

        await this.postForm(formData);
    }

    saveForm = async (formValues) => {
        const formData = this.prepareFormToSend(formValues);

        await this.postForm(formData);
    }

    onRezNumberChange = (rezValue) => {
        this.setState({
            ...this.state,
            formDetails: {
                ...this.state.formDetails,
                invalidReservation: false,
                reservationDownloaded: false
            },
        });
    }

    postForm = async (formData) => {
        this.setState({ ...this.state, requestInProgress: true });
        const token = await authService.getAccessToken();

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(formData)
        };
        fetch('forms', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.message) {
                    this.setState({ ...this.state, errorMsg: data.message })
                } else {
                    if (data.id > 0) {
                        this.setState({ ...this.state, showFormDetailsPopup: false, formDetails: {}, requestInProgress: false })
                        this.populateData();
                    }
                }
            });
    }

    calculateClasses = (status) => {
        let classes = "reward-form-status-text ";
        switch (status) {
            case 0:
                classes += "text-orange";
                break;
            case 1:
                classes += "text-green"
                break;
            case 2:
                classes += "text-red"
                break;
        }
        return classes;
    }

    errorMsgClose = () => {
        this.setState({
            ...this.state,
            errorMsg: '',
            requestInProgress: false
        });
    }

    renderPanel(member) {
        var statusClasses = classNames({
            'reward-form-status-text': true,
            'text-orange': member.rewardFormStatus === 0,
            'text-green': member.rewardFormStatus === 1,
            'text-red': member.rewardFormStatus === 2,
        });


        const tooltipText = `<span class="font-bold">${member.agentFirstName} ${member.agentLastName} (${member.agentId} / ${member.agencyNumber})<br/>${member.agencyAddress}</span>`;
        return (
            <div>
                <ReactTooltip type="light" html={true} />
                <div className="d-flex justify-content-between">
                    <div className="header" >
                    {i18n.t('welcome')} {member.name}!
                    </div>
                    <div>
                        <FiInfo data-tip={tooltipText} />
                    </div>
                </div>
                {member.suspended ? 
                    <div className="summary-regular" style={{marginBottom: "15px"}}>
                        <span style={{color: "red"}}>{i18n.t('your activity was suspended on')} {new Date(member.suspendedDate).toLocaleDateString()}</span>
                        <br/>
                        {i18n.t('question contact')}
                        <br/>
                    </div>
                    : null}
                <div className="sub-header">
                {i18n.t('sales summary')}
                </div>
                <div className="summary-regular">
                {i18n.t('points to use')} {member.points.toLocaleString('en').replace(/,/g, " ")}
                </div>
                <div className="summary-regular">
                {i18n.t('potential points_')} {member.potentialPoints.toLocaleString('en').replace(/,/g, " ")}
                </div>
                {member.suspended ? 
                <div className="summary-regular">
                    {i18n.t('counting suspended')}
                </div>
                : null}
                <div className="target-header mt-50">
                {i18n.t('your goal')} {member.target.name} <span className="target-points">({member.target.points}{i18n.t("pkt")})</span>
                </div>
                <img className="w-100 border-radius mt-12" src={member.target.base64Image} />
                <div className="target-header text-center mt-10">
                    {member.target.percentageProgress}%
                </div>
                <div className="progress-bar-container">
                    <div className="progress-bar" style={{ width: member.target.percentageProgress + '%' }} />
                </div>
                <div className="target-points mt-10 text-end">
                {i18n.t('missing')} {member.target.missingPoints}{i18n.t("pkt")}
                </div>
                {
                    member.rewardFormSentAt
                        ? <div className={statusClasses}>
                            {member.rewardFormStatusText}
                        </div>
                        : null
                }

                <div className="target-header mt-50">
                {i18n.t('want change goal')}
                </div>
                <div className="summary-regular">
                {i18n.t('select reward from list')}
                </div>
                <div className="mb-35 mt-10">
                    <button className="button button-grey" onClick={() => this.onRewardListClick()}>{i18n.t("rewards list")}</button>
                </div>
                <div className="d-flex flex-column mb14" onClick={() => this.showFormDetails(member)}>
                    <div className={this.canShowFormDetails(member) ? "static-link" : "static-link-inactive"}>{i18n.t("collect reward")}</div>
                    {
                        member.rewardFormSentAt
                            ? <span className="target-points">{i18n.t('sent')} {new Date(member.rewardFormSentAt).toLocaleDateString()}</span>
                            : null
                    }
                </div>
                <div className="d-flex flex-row align-items-end">
                    <div className="static-link mr6" onClick={this.toggleAgreementPopup}>
                    {i18n.t('regulations')}
                    </div>
                    <span className="target-points">{i18n.t('accepted')} {new Date(this.props.config.regulationsLastAcceptDate).toLocaleDateString()}</span>
                </div>
                <div className="static-link mb14 mt-14" onClick={this.toggleRealizeNotePopup}>{i18n.t('how to realize reward')}</div>
                {this.props.config.additionalInfo ?
                <div className="static-link mb14 mt-14" onClick={this.toggleAdditionalInfoPopup}>{i18n.t('additional info')}</div>
                : null}

                {this.state.showRealizeNotePopup ?
                    <RealizeNotePopup
                        closePopup={this.toggleRealizeNotePopup}
                        realizeNote={this.props.config.realizeNote}
                        readonly="true"
                    />
                    : null
                }
                {this.state.showAdditionalInfoPopup ?
                    <AdditionalInfoPopup
                        closePopup={this.toggleAdditionalInfoPopup}
                        info={this.props.config.additionalInfo}
                        readonly="true"
                    />
                    : null
                }

                {this.state.showAgreementPopup ?
                    <AgreementPopup
                        agreement={this.props.config.agreement}
                        acceptable={false}
                        acceptAgreement={this.toggleAgreementPopup} />
                    : null
                }

                {this.state.showDownloadDataApprovalPopup ?
                    <DownloadDataApprovalPopup
                        openEmptyRewardForm={this.openEmptyRewardForm}
                        downloadPreviousData={this.downloadPreviousData}
                        closePopup={this.toggleDownloadDataApprovalPopup}
                    />
                    : null
                }

                {this.state.showFormDetailsPopup ?
                    <FormDetailsPopup
                        formDetails={this.state.formDetails}
                        taxOffices={this.state.taxOffices}
                        requestInProgress={this.state.requestInProgress}
                        downloadReservations={this.downloadReservations}
                        closePopup={this.toggleFormDetailsPopup}
                        mainAction={this.submitForm}
                        secondaryAction={this.saveForm}
                        onRezNumberChange={this.onRezNumberChange}
                    />
                    : null
                }

                {this.state.showSelectRewardPopup ?
                    <SelectRewardsListPopup
                        closePopup={this.toggleSelectRewardPopup}
                        markReward={this.markReward}
                        saveReward={this.saveReward}
                        sort={this.sortRewards}
                        filter={this.filterRewards}
                        rewards={this.state.rewards}
                        userPoints={this.state.member.points}
                        requestInProgress={this.state.requestInProgress}
                    />
                    : null
                }

                {this.state.errorMsg ?
                    <ErrorMsg
                        errorMsg={this.state.errorMsg}
                        close={this.errorMsgClose} />
                    : null
                }

                {
                    this.state.showYesNoDialog ?
                        <YesNoGenericPopup
                            closePopup={this.toggleYesNoPopup}
                            onNo={this.toggleYesNoPopup}
                            onYes={this.downloadRewards}
                        />
                        : null
                }

                {
                    this.state.showGenericInfoPopup ?
                        <GenericInfoPopup
                            closePopup={this.toggleInfoPopup}
                            infoText={'Zmiana nagrody jest niemożliwa w czasie rozpatrywania wysłanego formularza'}
                        />
                        : null
                }

            </div >
        );
    }

    renderLoader() {
        return (
            <div className="center-table-cell">
                <Audio
                    height="50"
                    width="50"
                    color='grey'
                    ariaLabel='loading'
                    wrapperClass='d-block'
                />
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? this.renderLoader()
            : this.renderPanel(this.state.member);

        return (
            <div>
                {contents}
            </div>
        );
    }

    async populateData() {
        const token = await authService.getAccessToken();
        const response = await fetch('member', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        const formDetails = this.initFormDetails(data);

        this.setState({
            member: data,
            formDetails: formDetails,
            taxOffices: data.taxOffices.map((t) => ({ label: t.name, value: t.id })),
            loading: false,
            showSelectRewardPopup: false
        });
    }

    initFormDetails = (data) => {
        return {
            agentId: data.agentId,
            agentFirstName: data.agentFirstName,
            agentLastName: data.agentLastName,
            rewardPoints: data.rewardPoints,
            rewardCode: data.rewardCode,
            rewardDirection: data.rewardDirection,
            rewardName: data.rewardName,
            missingPoints: data.missingPoints,
            percentageProgress: data.target.percentageProgress,
        }
    }

    downloadRewards = async () => {
        this.setState({ ...this.state, requestInProgress: true, showSelectRewardPopup: true, showYesNoDialog: false })

        const token = await authService.getAccessToken();
        const response = await fetch('reward', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.prepareRewards(data);
    }

    prepareFormToSend = (formValues) => {
        return {
            ...this.state.formDetails,
            ...formValues,
            agentDoB: this.state.formDetails.agentDoB || formValues.agentDoB,
            taxOfficeCode: this.state.formDetails.taxOfficeCode || formValues.taxOfficeCode,
        };
    }
}
