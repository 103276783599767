import React, { Component } from 'react';
import '../custom.css';
import authService from './api-authorization/AuthorizeService'
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js'
import { GrClose } from 'react-icons/gr';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import YearCarousel from './YearCarousel'
import { SortSelect } from './SortSelect'
import i18n from 'i18next';

export class Statistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestInProgress: false,

            allPotentialPointsPerMonth: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            allEarnedPointsPerMonth: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],

            agentPotentialPointsPerMonth: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            agentEarnedPointsPerMonth: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],

            agencyPotentialPointsPerMonth: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            agencyEarnedPointsPerMonth: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],

            agencies: ['0000'],
            agents: ['0000'],

            agencyYear: 0,
            agentYear: 0,
            allYear: 0,
            selectedAgency: undefined,
            selectedAgent: undefined,
        };

        ChartJS.register(
            CategoryScale,
            LinearScale,
            PointElement,
            LineElement,
            Title,
            Tooltip,
            Legend,
            Filler
        )
    }

    componentDidMount() {
        this.getStats(1);
    }

    handleAgencyNumberChange = (agencyIndex) => {
        var agency = this.state.agencies[agencyIndex];
        this.setState({ ...this.state, selectedAgency: agency }, () => this.getStats(3))
    }

    handleAgentNumberChange = (agentIndex) => {
        var agent = this.state.agents[agentIndex];
        this.setState({ ...this.state, selectedAgent: agent }, () => this.getStats(5))
    }

    handleAgencyYearChange = (year) => {
        this.setState({ ...this.state, agencyYear: year }, () => this.getStats(2))
    }

    handleAgentYearChange = (year) => {
        this.setState({ ...this.state, agentYear: year }, () => this.getStats(4))
    }

    handleAllYearChange = (year) => {
        this.setState({ ...this.state, allYear: year }, () => this.getStats(6))
    }

    render() {
        const months = [i18n.t("jan"),
                        i18n.t("feb"),
                        i18n.t("mar"),
                        i18n.t("apr"),
                        i18n.t("may"),
                        i18n.t("jun"),
                        i18n.t("july"),
                        i18n.t("aug"),
                        i18n.t("sept"),
                        i18n.t("oct"),
                        i18n.t("nov"),
                        i18n.t("dec")]

        const statsByAgentsData = {
            labels: months,
            datasets: [
                {
                    label: i18n.t('pot points'),
                    data: this.state.agentPotentialPointsPerMonth,
                    fill: false,
                    borderColor: "rgba(255,0,0,1)",
                    tension: 0.4
                },
                {
                    label: i18n.t('couted points'),
                    data: this.state.agentEarnedPointsPerMonth,
                    fill: true,
                    borderColor: "rgba(26,77,45,1)",
                    backgroundColor: "rgba(26,77,45,0.5)",
                    tension: 0.4
                }
            ]
        };

        const statsByAgenciesData = {
            labels: months,
            datasets: [
                {
                    label: i18n.t('pot points'),
                    data: this.state.agencyPotentialPointsPerMonth,
                    fill: false,
                    borderColor: "rgba(255,0,0,1)",
                    tension: 0.4
                },
                {
                    label: i18n.t('couted points'),
                    data: this.state.agencyEarnedPointsPerMonth,
                    fill: true,
                    borderColor: "rgba(26,77,45,1)",
                    backgroundColor: "rgba(26,77,45,0.5)",
                    tension: 0.4
                }
            ]
        };

        const statsAllAgenciesData = {
            labels: months,
            datasets: [
                {
                    label: i18n.t('pot points'),
                    data: this.state.allPotentialPointsPerMonth,
                    fill: false,
                    borderColor: "rgba(255,0,0,1)",
                    tension: 0.4
                },
                {
                    label: i18n.t('couted points'),
                    data: this.state.allEarnedPointsPerMonth,
                    fill: true,
                    borderColor: "rgba(26,77,45,1)",
                    backgroundColor: "rgba(26,77,45,0.5)",
                    tension: 0.4
                }
            ]
        };

        return (
            <div className="stats-relative-container">
                <div className="form-list-wrapper">

                    <div className="d-flex flex-row justify-content-between" >
                        <div className="table-tab-header-static text-begin">
                        {i18n.t('statistics')}
                        </div>
                        <NavLink tag={Link} to="/"><GrClose role="button" /></NavLink>
                    </div>
                    <div className="baner-header" style={{ marginTop: "30px" }}>
                    {i18n.t('dynamics chart agency')}
                     </div>
                    <div className="baner-regular">
                    {i18n.t('points in time')}
                    </div>
                    <div className="d-flex flex-row justify-content-between" style={{ marginBottom: "25px", marginTop: "25px" }}>
                        <YearCarousel yearChanged={this.handleAgencyYearChange} />
                        <div className="d-flex flex-row align-items-center">
                            <div className="d-flex sort-select-prefix">{i18n.t('agency')}:</div>
                            <div className="d-flex">
                                <SortSelect searchable={true} optionsList={this.state.agencies} selectedValue={this.state.selectedAgency} setSelectedOption={this.handleAgencyNumberChange} />
                            </div>
                        </div>
                    </div>

                    <div className="chart-wrapper" >
                        <Line data={statsByAgenciesData} height={250} options={{ maintainAspectRatio: false }} />
                    </div>

                    <div className="baner-header" style={{ marginTop: "30px" }}>
                    {i18n.t('dynamics chart agents')}
                    </div>
                    <div className="baner-regular">
                    {i18n.t('points in time')}
                    </div>
                    <div className="d-flex flex-row justify-content-between" style={{ marginBottom: "25px", marginTop: "25px" }}>
                        <YearCarousel yearChanged={this.handleAgentYearChange} />
                        <div className="d-flex flex-row align-items-center">
                            <div className="d-flex sort-select-prefix">Agent:</div>
                            <div className="d-flex">
                                <SortSelect optionsList={this.state.agents} selectedValue={this.state.selectedAgent} setSelectedOption={this.handleAgentNumberChange} />
                            </div>
                        </div>
                    </div>

                    <div className="chart-wrapper" >
                        <Line data={statsByAgentsData} height={250} options={{ maintainAspectRatio: false }} />
                    </div>

                    <div className="baner-header" style={{ marginTop: "30px" }}>
                    {i18n.t('dynamics chart all')}
                    </div>
                    <div className="baner-regular">
                    {i18n.t('points in time')}
                    </div>
                    <div className="d-flex flex-row justify-content-between" style={{ marginBottom: "25px", marginTop: "25px" }}>
                        <YearCarousel yearChanged={this.handleAllYearChange} />
                    </div>

                    <div className="chart-wrapper" >
                        <Line data={statsAllAgenciesData} height={250} options={{ maintainAspectRatio: false }} />
                    </div>
                    {this.state.requestInProgress && <div className="overlay" />}
                </div>
            </div>
        );
    }

    getStats = async (searchType) => {
        this.setState({ ...this.state, requestInProgress: true });
        const token = await authService.getAccessToken();

        const json = {
            agencyYear: this.state.agencyYear,
            agentYear: this.state.agentYear,
            allYear: this.state.allYear,
            selectedAgency: this.state.selectedAgency,
            selectedAgent: this.state.selectedAgent,
            searchType: searchType
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(json)
        };

        fetch('stats', requestOptions)
            .then(response => response.json())
            .then(data => {

                let selectedAgent = this.state.selectedAgent;

                // reload agencies
                if (searchType === 3) {
                    selectedAgent = data.agents.length > 0 ? data.agents[0] : undefined;
                }

                this.setState({
                    ...this.state,
                    ...data,
                    requestInProgress: false,
                    agencies: data.agencies,
                    agents: data.agents,
                    selectedAgent: selectedAgent
                });
            });
    }
}
